import isProd from 'utils/isProd';
import isBuoyStaging from 'utils/isBuoyStaging';
import Environments from 'constants/Environments';
import isBuoyDev from 'utils/isBuoyDev';

const getRequestBase = (localPort: number) => {
  if (isProd()) {
    return `https://${Environments.PRODUCTION.host}`;
  } else if (isBuoyStaging()) {
    return `https://${Environments.STAGING.host}`;
  } else if (isBuoyDev()) {
    return window.location.origin;
  } else {
    return `http://localhost:${localPort}`;
  }
};

/** Begin UGC */

const BUOY_UGC_URL = `${getRequestBase(
  Environments.LOCAL.buoyUgcPort,
)}/ugc/api/v1/`;
const USER_STORIES_UGC_ENDPOINT = `${BUOY_UGC_URL}user-stories/`;
const RX_REVIEWS_UGC_ENDPOINT = `${BUOY_UGC_URL}rx-reviews/`;
const RX_MOST_POPULAR_SIDE_EFFECTS_UGC_ENDPOINT = `${BUOY_UGC_URL}side-effects/most-popular/`;
const ARTICLES_UGC_ENDPOINT = `${BUOY_UGC_URL}articles/`;

/** End UGC */

export const SITE_API_URL = `${getRequestBase(
  Environments.LOCAL.siteApiPort,
)}/api`;

export const BUOY_GPT_URL = `${getRequestBase(
  Environments.LOCAL.buoyGptPort,
)}/buoy-gpt`;

function getGeocodeBase() {
  if (isProd() || isBuoyStaging()) {
    return `http://${Environments.PRODUCTION.geocodeHost}`;
  } else {
    return `http://${Environments.LOCAL.geocodeHost}`;
  }
}

const GEOCODE_BASE = `${getGeocodeBase()}`;

function getLocationBase() {
  if (isProd()) {
    return `https://${Environments.PRODUCTION.host}`;
  }
  return `https://${Environments.STAGING.host}`;
}

const BUOY_LOCATION_URL = `${getLocationBase()}/location`;

const EMAIL_SUBSCRIBE_INTERNAL_URL = '/api/public-site/convertkit';

const stateCodes = [
  'AL',
  'AK',
  'AS',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FM',
  'FL',
  'GA',
  'GU',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MH',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'MP',
  'OH',
  'OK',
  'OR',
  'PW',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VI',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

// Blog pagination constants
const BLOG_CATEGORY_PAGE_SIZE = 9;
// Article pagination constants
const ARTICLE_PAGE_SIZE = 9;
// Quiz pagination constants
const QUIZ_PAGE_SIZE = 9;

export {
  ARTICLES_UGC_ENDPOINT,
  BLOG_CATEGORY_PAGE_SIZE,
  BUOY_LOCATION_URL,
  ARTICLE_PAGE_SIZE,
  EMAIL_SUBSCRIBE_INTERNAL_URL,
  GEOCODE_BASE,
  getRequestBase,
  QUIZ_PAGE_SIZE,
  RX_MOST_POPULAR_SIDE_EFFECTS_UGC_ENDPOINT,
  RX_REVIEWS_UGC_ENDPOINT,
  stateCodes,
  USER_STORIES_UGC_ENDPOINT,
};
