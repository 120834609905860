import theme from 'styled/theme';
import styled from 'styled-components';

export const MenuItems = styled.ul`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
`;

export const MenuItem = styled.li`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  width: 280px;

  span {
    ${theme.typography.variants.primary.xs}
    padding: 0;
  }

  .Link {
    justify-content: space-between;
    padding: 1rem;
    width: 100%;

    > span {
      line-height: 1.2rem;
      font-size: 1rem;
    }
  }

  .Link__label {
    white-space: normal;
  }
`;

export const GridMenuItems = styled(MenuItems)`
  display: grid;
  grid-template-columns: 1fr;
`;

export const GridMenuItemContainer = styled.div<{ $isActive: boolean }>`
  grid-row-start: 1;
  grid-column-start: 1;
  opacity: ${({ $isActive }) => ($isActive ? 1 : 0)};
  pointer-events: ${({ $isActive }) => ($isActive ? 'auto' : 'none')};
  max-height: 50vh;
  overflow: scroll;

  &::-webkit-scrollbar {
    appearance: none;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.palette.gray[40]};
    border-radius: 6px;
  }
`;
