import { trackStructuredEvent } from 'analytics';

const category = 'navigation';

// Mobile only
export const trackUserOpensMobileNav = () => {
  trackStructuredEvent({
    category,
    action: 'mobile-nav-opened',
  });
};

// Desktop and mobile
export const trackUserClicksTopLevelItem = (label: string, slug: string) => {
  trackStructuredEvent({
    action: 'top-level-item-clicked',
    category,
    label,
    property: slug,
  });
};

// Desktop and mobile
export const trackUserExpandsTopLevelSection = (label: string) => {
  trackStructuredEvent({
    category,
    action: 'top-level-item-expanded',
    label,
  });
};

// Desktop only
export const trackUserClicksSecondLevelItem = (label: string, slug: string) => {
  trackStructuredEvent({
    category,
    action: 'second-level-item-clicked',
    label,
    property: slug,
  });
};

// Desktop and mobile
export const trackUserExpandsSecondLevelItem = (label: string) => {
  trackStructuredEvent({
    category,
    action: 'second-level-item-expanded',
    label,
  });
};

// Desktop only
export const trackUserClicksThirdLevelItem = (label: string, slug: string) => {
  trackStructuredEvent({
    category,
    action: 'third-level-item-clicked',
    label,
    property: slug,
  });
};

// Mobile only
export const trackUserExpandsThirdLevelItem = (label: string) => {
  trackStructuredEvent({
    category,
    action: 'third-level-item-expanded',
    label,
  });
};

// Mobile and desktop
export const trackUserClicksTrendingArticle = (label: string) => {
  trackStructuredEvent({
    category,
    action: 'trending-article-clicked',
    label,
  });
};

export const trackUserClicksOMDLink = (url: string) => {
  trackStructuredEvent({
    category,
    action: 'omd-nav-link-clicked',
    label: url,
  });
};
