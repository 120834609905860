import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  ReactNode,
} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { withRouter } from 'next/router';
import { WithRouterProps } from 'next/dist/client/with-router';
import Router from 'next/router';
import NProgress from 'nprogress';

import cx from 'classnames';
import throttle from 'lodash/throttle';
import { differenceInDays } from 'date-fns';
import localForage from 'localforage';
import { ThemeProvider } from 'styled-components';
import {
  StyledEngineProvider,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import {
  Theme,
  GlobalStyle,
  Button,
  NotificationBar,
  Footer,
  Navigation,
} from 'styled';

import orderedMainNavigation from 'state/selectors/orderedMainNavigation';

import {
  openNotificationBar,
  closeNotificationBar,
  setNotificationBarHeight,
} from 'state/actions/applicationUIActions';

import PopUp from 'components/PopUp';

import { RoutesWithoutFooter, RoutesWithMinimalNav } from 'constants/Routes';
import { PageMainContentId } from 'constants/Global';

import { GlobalState } from 'types';
import useLanguage from 'hooks/useLanguage';
import { fetchUserLocation } from 'state/actions/geolocationActions';
import { materialTheme } from 'styled/theme/materialTheme';
import EmailCollectionForm from 'styled/components/EmailCollectionForm';
import { PreviousRouteContextProvider } from 'state/contexts/PreviousRouteContext';
import PolarisPopUpContext from 'contexts/PolarisPopUpContext';
import NotificationBarContext from 'contexts/NotificationBarContext';
import EventEmitter from 'lib/EventEmitter';
import Ramp from './Playwire/Ramp';

import type { MiniInterviewType } from '@buoy-components/polaris/types';

interface PassedProps {
  children: ReactNode;
}

type Props = PassedProps &
  WithRouterProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

function Wrapper(props: Props) {
  const {
    actions,
    children,
    globalSettings,
    notificationBarHeight,
    notificationBarIsActive,
    orderedMainNavigation,
    researchPopUpIsActive,
    router,
    router: { asPath },
    testerRecruitmentPopUpIsActive,
  } = props;

  const Language = useLanguage();
  const notificationBar = useRef<HTMLDivElement>(null);
  const [notificationBarIsDismissed, setNotificationBarIsDismissed] =
    useState(false);

  const [activeInterview, setActiveInterview] = useState<MiniInterviewType>();
  const [isPolarisPopUpOpen, setIsPolarisPopUpOpen] = useState(false);
  const [isPolarisDisabled, setIsPolarisDisabled] = useState(true);

  const getNotificationBarHeight = useCallback(() => {
    if (
      typeof window != 'undefined' &&
      notificationBar.current &&
      globalSettings.notificationBar.isActive &&
      !notificationBarIsDismissed
    ) {
      const height = notificationBar.current.clientHeight;
      if (height !== notificationBarHeight) {
        actions.setNotificationBarHeight(height);
      }
    }
  }, [
    actions,
    globalSettings.notificationBar.isActive,
    notificationBarHeight,
    notificationBarIsDismissed,
  ]);

  const closeNotificationBar = useCallback(() => {
    actions.closeNotificationBar();
    setNotificationBarIsDismissed(true);
  }, [actions]);

  const openNotificationBar = useCallback(() => {
    if (
      globalSettings.notificationBar.isActive &&
      !notificationBarIsDismissed
    ) {
      localForage.getItem('notificationBarDismissDate', (_err, value) => {
        const difference = differenceInDays(
          new Date(),
          new Date(value as string),
        );

        if (difference >= 1) {
          actions.openNotificationBar();
          getNotificationBarHeight();
        }
      });
    }
  }, [
    actions,
    getNotificationBarHeight,
    globalSettings,
    notificationBarIsDismissed,
  ]);

  const throttleGetNotificationBarHeight = useCallback(
    throttle(getNotificationBarHeight, 500),
    [getNotificationBarHeight],
  );

  const eventEmitter = useMemo(() => new EventEmitter(), []);

  // Attach NProgress to client-side navigation events
  useEffect(() => {
    const handleChangeStart = () => {
      NProgress.start();

      // Unset scroll lock if a pop up was active and locked scroll.
      document.body.style.overflow = 'initial';
    };
    const handleChangeComplete = () => {
      openNotificationBar();
      NProgress.done();
    };
    const handleChangeError = () => {
      NProgress.done();
    };

    Router.events.on('routeChangeStart', handleChangeStart);
    Router.events.on('routeChangeComplete', handleChangeComplete);
    Router.events.on('routeChangeError', handleChangeError);

    return () => {
      Router.events.off('routeChangeStart', handleChangeStart);
      Router.events.off('routeChangeComplete', handleChangeComplete);
      Router.events.off('routeChangeError', handleChangeError);
    };
  }, [openNotificationBar]);

  // Re-calculate notification bar height on window resize
  useEffect(() => {
    openNotificationBar();

    window.addEventListener('resize', throttleGetNotificationBarHeight);

    return () => {
      window.removeEventListener('resize', throttleGetNotificationBarHeight);
    };
  }, [actions, openNotificationBar, throttleGetNotificationBarHeight]);

  // On mount side-effects
  useEffect(() => {
    actions.fetchUserLocation();
  }, [actions]);

  const hideFooter =
    RoutesWithoutFooter.some((basePath) => asPath.includes(basePath)) ||
    researchPopUpIsActive ||
    testerRecruitmentPopUpIsActive;
  const renderMinimalNav = RoutesWithMinimalNav.some((basePath) =>
    asPath.includes(basePath),
  );

  return (
    <>
      <Ramp />
      <GlobalStyle />
      <PreviousRouteContextProvider>
        <StyledEngineProvider injectFirst>
          <MuiThemeProvider theme={materialTheme}>
            <ThemeProvider theme={Theme}>
              <PolarisPopUpContext.Provider
                value={{
                  activeInterview,
                  eventEmitter,
                  isPolarisDisabled,
                  isPopupOpen: isPolarisPopUpOpen,
                  setActiveInterview,
                  setIsPolarisDisabled,
                  setIsPopupOpen: setIsPolarisPopUpOpen,
                }}
              >
                <NotificationBarContext.Provider
                  value={{
                    isActive: notificationBarIsActive || false,
                    height: notificationBarHeight,
                  }}
                >
                  <Button
                    variant="skip-link"
                    ariaLabel={Language.t('SkipLinks.skipToMainContent')}
                    to={`#${PageMainContentId}`}
                    openInCurrentTab={true}
                  >
                    {Language.t('SkipLinks.skipToMainContent')}
                  </Button>
                  {/** Uncomment when accessibility page is re-published. */}
                  {/* <Button
              variant="skip-link"
              ariaLabel={Language.t('SkipLinks.skipToAccessibilityServices')}
              to={RouteMap.ACCESSIBILITY.path}
              openInCurrentTab={true}
            >
              {Language.t('SkipLinks.skipToAccessibilityServices')}
            </Button> */}
                  <main className="App relative">
                    {((notificationBarIsActive &&
                      notificationBarHeight !== 0) ||
                      !notificationBarIsActive) && (
                      <div
                        className="relative"
                        style={{
                          paddingTop: notificationBarIsActive
                            ? `${notificationBarHeight - 1}px`
                            : '0',
                        }}
                      >
                        <div className="relative">
                          <Navigation
                            router={router}
                            navigation={globalSettings.navigation}
                            orderedMainNavigation={orderedMainNavigation}
                            notificationBarIsActive={notificationBarIsActive}
                            notificationBarHeight={notificationBarHeight}
                            renderMinimalNav={renderMinimalNav}
                          />
                          <div
                            className={cx('App__page relative flex flex-col', {
                              'App__page--with-minimal-nav': renderMinimalNav,
                            })}
                          >
                            {children}
                          </div>
                        </div>
                        {!hideFooter && (
                          <Footer
                            footer={globalSettings.footer}
                            pageHasMinimalNav={renderMinimalNav}
                          />
                        )}
                      </div>
                    )}
                    <EmailCollectionForm />
                    <PopUp popUp={globalSettings.popUp} />
                    <NotificationBar
                      closeNotificationBar={closeNotificationBar}
                      elemRef={notificationBar}
                      notificationBar={globalSettings.notificationBar}
                      notificationBarIsActive={notificationBarIsActive}
                    />
                  </main>
                </NotificationBarContext.Provider>
              </PolarisPopUpContext.Provider>
            </ThemeProvider>
          </MuiThemeProvider>
        </StyledEngineProvider>
      </PreviousRouteContextProvider>
    </>
  );
}

const mapStateToProps = (state: GlobalState) => ({
  globalSettings: state.globalSettings,
  orderedMainNavigation: orderedMainNavigation(state),
  notificationBarIsActive: state.applicationUI.notificationBarIsActive,
  notificationBarHeight: state.applicationUI.notificationBarHeight,
  researchPopUpIsActive: state.applicationUI.researchPopUpIsActive,
  testerRecruitmentPopUpIsActive:
    state.applicationUI.testerRecruitmentPopUpIsActive,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators(
    {
      openNotificationBar,
      closeNotificationBar,
      setNotificationBarHeight,
      fetchUserLocation,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Wrapper));
