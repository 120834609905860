export default {
  STAGING: {
    /*
     * Full list of staging urls:
     * https://buoy-site-redesign.buoyhealth.now.sh/
     * https://buoy-site-redesign-git-master.buoyhealth.now.sh/
     * https://buoy-site-redesign.now.sh/
     * https://buoy-site-redesign.buoyhealth.vercel.app/
     * https://buoy-site-redesign-git-master.buoyhealth.vercel.app/
     */
    vercelHost: 'buoy-site-redesign', // Shared segment of hostnames
    host: 'staging.buoyhealth.com',
  },
  PRODUCTION: {
    host: 'www.buoyhealth.com',
    geocodeHost: 'buoy-geocode:9002',
  },
  LOCAL: {
    host: 'localhost:3000',
    portalHost: 'localhost:3001',
    bazaarPort: 9001,
    geocodeHost: 'localhost:9002',
    siteApiPort: 8001,
    buoyGptPort: 8091,
    buoyUgcPort: 9003,
    buoyDocumentsPort: 8000,
  },
};
