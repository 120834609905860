// This client is used for non-Sanity related content actions

import axios from 'axios';
import qs from 'querystring';
import get from 'lodash/get';
import ClientCookies from 'js-cookie';

import HttpStatusCodes from 'constants/HttpStatusCodes';
import { SurveyResponseStatuses } from 'constants/SurveyMonkey';
import { getFeatureFlags } from 'lib/featureflags/utils';
import sanitizeArticleUserStories from 'state/sanitizers/sanitizeArticleUserStories';
import sanitizeSurveyResponse from 'state/sanitizers/sanitizeSurveyResponse';
import sanitizeDrugReviews from 'state/sanitizers/sanitizeDrugReviews';
import formatSurveyResponseRequestBody from 'utils/formatSurveyResponseRequestBody';

import {
  Article,
  ArticleUserStories,
  BuoyLocationResponse,
  DrugPage,
  DrugReview,
  DrugReviewFormBody,
  DrugReviews,
  DrugSideEffectUgc,
  FeatureFlags,
  FeatureFlagsContext,
  FormValues,
  GeocodeResponse,
  IEmailSubscribeForm,
  SurveyResponse,
  SurveyResponsePagesById,
} from 'types';
import {
  EMAIL_SUBSCRIBE_INTERNAL_URL,
  BUOY_LOCATION_URL,
  ARTICLES_UGC_ENDPOINT,
  RX_MOST_POPULAR_SIDE_EFFECTS_UGC_ENDPOINT,
  RX_REVIEWS_UGC_ENDPOINT,
  USER_STORIES_UGC_ENDPOINT,
} from './constants';
import { GEOLOCATION } from 'constants/Geolocation';

const BuoyClient: {
  getArticleUserStories(
    articleId: string,
    limit: number,
    offset: number,
  ): Promise<ArticleUserStories>;
  getArticleOrDrugPageRatings(
    id: Article['id'] | DrugPage['id'],
  ): Promise<{ likes: number; dislikes: number }>;
  upvoteArticleOrDrugPage(id: Article['id'] | DrugPage['id']): Promise<{
    id: Article['id'] | DrugPage['id'];
    likes: number;
  }>;
  downvoteArticleOrDrugPage(id: Article['id'] | DrugPage['id']): Promise<{
    id: Article['id'] | DrugPage['id'];
    dislikes: number;
  }>;
  postArticleUserStory(
    slug: string,
    id: string,
    formValues: FormValues,
  ): Promise<any>;
  postWebToLeadForm(form: FormValues): Promise<any>;
  postWebToLeadFormViaChiliPiper(form: FormValues): Promise<any>;
  createSurveyResponse(
    surveyResponsePages: SurveyResponsePagesById,
    surveyIsComplete: boolean,
    customVariables: {
      [name: string]: any;
    },
  ): Promise<any>;
  updateSurveyResponse(
    surveyResponse: SurveyResponse,
    surveyIsComplete: boolean,
  ): Promise<any>;
  postDrugReview(review: DrugReviewFormBody): Promise<any>;
  getDrugReviews(
    drugPageId: DrugPage['id'],
    limit: number,
    offset: number,
  ): Promise<DrugReviews>;
  getPopularDrugSideEffects(
    drugPageId: DrugPage['id'],
  ): Promise<DrugSideEffectUgc[]>;
  upvoteDrugReview(id: DrugReview['id']): Promise<{
    id: DrugReview['id'];
    likes: number;
  }>;
  getFeatureFlags(context: FeatureFlagsContext): Promise<FeatureFlags>;
  getBrowserLocation(): Promise<GeocodeResponse>;
  postEmailSubscribeForm(form: IEmailSubscribeForm): Promise<any>;
  postInsuranceForm(form: FormValues): Promise<any>;
} = {
  async getArticleUserStories(articleId, limit, offset) {
    const currentPageNumber = offset / limit + 1;

    const response = await axios
      .get(
        `${USER_STORIES_UGC_ENDPOINT}?article_id=${articleId}&limit=${limit}&offset=${offset}&approved=approved&ordering=-points,-submitted`,
      )
      .catch((err) =>
        console.error(Error(get(err, 'message', 'Unknown error'))),
      );

    return sanitizeArticleUserStories(
      get(response, 'data', {}),
      currentPageNumber,
    );
  },
  async getArticleOrDrugPageRatings(id) {
    const response = await axios
      .get(`${ARTICLES_UGC_ENDPOINT}${id}/`)
      .catch((err) =>
        console.error(Error(get(err, 'message', 'Unknown error'))),
      );

    return {
      likes: get(response, 'data.upvotes', 0),
      dislikes: get(response, 'data.downvotes', 0),
    };
  },
  async upvoteArticleOrDrugPage(id) {
    const response = await axios
      .post(`${ARTICLES_UGC_ENDPOINT}${id}/upvote/`, {
        id,
        points: 1,
      })
      .catch((err) =>
        console.error(Error(get(err, 'message', 'Unknown error'))),
      );

    if (get(response, 'status') === HttpStatusCodes.OK) {
      return Promise.resolve({
        id: get(response, 'data.id', ''),
        likes: get(response, 'data.upvotes', 0),
      });
    } else {
      return Promise.reject('Unknown error');
    }
  },
  async downvoteArticleOrDrugPage(id) {
    const response = await axios
      .post(`${ARTICLES_UGC_ENDPOINT}${id}/upvote/`, {
        id,
        points: -1,
      })
      .catch((err) =>
        console.error(Error(get(err, 'message', 'Unknown error'))),
      );

    if (get(response, 'status') === HttpStatusCodes.OK) {
      return Promise.resolve({
        id: get(response, 'data.id', ''),
        dislikes: get(response, 'data.downvotes', 0),
      });
    } else {
      return Promise.reject('Unknown error');
    }
  },
  async postArticleUserStory(slug, id, form) {
    const body = {
      article_id: id,
      first_initial: get(form, 'name', '').charAt(0),
      body: get(form, 'story', ''),
      title: get(form, 'title', ''),
    };

    const buoyResponse = await axios({
      method: 'post',
      url: USER_STORIES_UGC_ENDPOINT as string,
      data: body,
      headers: { 'Content-Type': 'application/json' },
    }).catch((err) => Promise.reject(err));

    return get(buoyResponse, 'data', '');
  },
  // Submit data directly to Salesforce WebToLead Form URL with query string parameters
  async postWebToLeadForm(form) {
    const requestBody: {
      [key: string]: string;
    } = {
      ...form,
      oid: '00Df4000002cEZv',
    };

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    const response = await axios
      .post(
        process.env.postWebToLeadFormRequestUrl as string,
        qs.stringify(requestBody),
        config,
      )
      .catch((err) => console.error(err));

    return get(response, 'data', '');
  },
  async postWebToLeadFormViaChiliPiper(form) {
    const submitLeadToChiliPiper = () => {
      return new Promise((resolve, reject) => {
        try {
          if (
            window.ChiliPiper &&
            typeof window.ChiliPiper.submit === 'function'
          ) {
            window.ChiliPiper.submit('buoyhealth', 'inbound-router', {
              lead: form,
              injectRootCss: true,
              map: true,
              onRouted: () => resolve('Calendar is shown'),
              onError: () =>
                reject('No queue has been found, calendar cannot be shown'),
            });
          } else {
            reject('Chili Piper error');
          }
        } catch {
          reject('Chili Piper is not available');
        }
      });
    };

    return await submitLeadToChiliPiper();
  },
  async createSurveyResponse(
    surveyResponsePages,
    surveyIsComplete,
    customVariables,
  ) {
    const response = await axios
      .post('/api/public-site/survey_responses', {
        response_status: surveyIsComplete
          ? SurveyResponseStatuses.COMPLETED
          : SurveyResponseStatuses.PARTIAL,
        pages: formatSurveyResponseRequestBody(surveyResponsePages),
        custom_variables: customVariables,
      })
      .catch((err) => Promise.reject(err));

    return sanitizeSurveyResponse(get(response, 'data'));
  },
  async updateSurveyResponse(surveyResponse, surveyIsComplete) {
    const response = await axios
      .put(`/api/public-site/survey_responses/${surveyResponse.id}`, {
        response_status: surveyIsComplete
          ? SurveyResponseStatuses.COMPLETED
          : SurveyResponseStatuses.PARTIAL,
        pages: formatSurveyResponseRequestBody(surveyResponse.pages),
      })
      .catch((err) => Promise.reject(err));

    return sanitizeSurveyResponse(get(response, 'data'));
  },
  async postDrugReview(drugReview) {
    const body = {
      article_id: drugReview.id,
      rating: drugReview.rating,
      review: drugReview.description,
      duration: drugReview.duration?.value,
      side_effect_management: drugReview.sideEffectManagement,
      side_effects: drugReview.sideEffects.map((sideEffect) => ({
        name: sideEffect.value,
      })),
      first_initial: drugReview.firstNameInitial,
      email: drugReview.email,
    };

    const response = await axios
      .post(RX_REVIEWS_UGC_ENDPOINT, body)
      .catch((err: Error) => {
        return Promise.reject(get(err, 'message', 'Unknown error'));
      });

    if (get(response, 'status') === HttpStatusCodes.CREATED) {
      return Promise.resolve(get(response, 'statusText', 'Unknown error'));
    } else {
      return Promise.reject('Unknown error');
    }
  },
  async getDrugReviews(drugPageId, limit, offset) {
    const currentPageNumber = offset / limit + 1;

    const response = await axios
      .get(
        `${RX_REVIEWS_UGC_ENDPOINT}?article_id=${drugPageId}&limit=${limit}&offset=${offset}&approved=approved&ordering=-points,-submitted`,
      )
      .catch((err) =>
        console.error(Error(get(err, 'message', 'Unknown error'))),
      );

    return sanitizeDrugReviews(get(response, 'data', {}), currentPageNumber);
  },
  async getPopularDrugSideEffects(drugPageId) {
    const response = await axios
      .get(
        `${RX_MOST_POPULAR_SIDE_EFFECTS_UGC_ENDPOINT}?article_id=${drugPageId}`,
      )
      .catch((err) =>
        console.error(Error(get(err, 'message', 'Unknown error'))),
      );

    return get(response, 'data', []).map((sideEffect: any) => {
      return {
        name: get(sideEffect, 'name', ''),
        count: get(sideEffect, 'count', 0),
        reviews: get(sideEffect, 'reviews', 0),
      };
    });
  },
  async upvoteDrugReview(id) {
    const response = await axios
      .post(`${RX_REVIEWS_UGC_ENDPOINT}${id}/upvote/`, {
        id,
        points: 1,
      })
      .catch((err) =>
        console.error(Error(get(err, 'message', 'Unknown error'))),
      );

    if (get(response, 'status') === HttpStatusCodes.OK) {
      return Promise.resolve({
        id: get(response, 'data.id', ''),
        likes: get(response, 'data.upvotes', 0),
      });
    } else {
      return Promise.reject('Unknown error');
    }
  },
  async getFeatureFlags(context) {
    return getFeatureFlags(context);
  },
  async getBrowserLocation() {
    const geolocationCookie = ClientCookies.get(GEOLOCATION.cookieName);
    if (geolocationCookie && geolocationCookie !== 'undefined') {
      // don't refetch if we already have this as a cookie
      return JSON.parse(geolocationCookie);
    }
    const response = await axios
      .get<BuoyLocationResponse>(BUOY_LOCATION_URL)
      .catch((err) => {
        return Promise.reject(err);
      });

    const parseBuoyLocationResponse: (
      locationResponse: BuoyLocationResponse,
    ) => GeocodeResponse = (locationData) => {
      return {
        admin1: locationData['country-region-name'],
        admin1_code: locationData['country-region'],
        city: locationData.city,
        country: locationData['country-name'],
        country_code: locationData['country'],
        latitude: parseFloat(locationData.latitude),
        longitude: parseFloat(locationData.longitude),
        postal: locationData['postal-code'],
      };
    };

    const loc = parseBuoyLocationResponse(response.data);
    return loc;
  },
  async postEmailSubscribeForm(form) {
    try {
      const response = await axios.post(EMAIL_SUBSCRIBE_INTERNAL_URL, {
        condition_name: form.conditionName,
        email: form.email,
        pathname: form.pathname,
      });
      if (get(response, 'status') === HttpStatusCodes.CREATED) {
        return get(response, 'statusText', 'Form submitted');
      } else {
        return Promise.reject('Unknown error');
      }
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async postInsuranceForm(form) {
    let status;
    let message;
    try {
      const data = await axios.post('/api/public-site/insurance', form);
      if (data.status === 200) {
        status =
          data.data?.result?._text === 'success'
            ? 'insurance-form-success'
            : 'insurance-form-rejected';
        message = data.data?.lead_id?._text;
      } else {
        status = 'insurance-form-error';
        message = data.data?.msg?._text;
      }
    } catch (error) {
      status = 'insurance-form-error';
      if (error instanceof Error) {
        message = error.message;
      } else {
        message = String(error);
      }
    }
    return { status, message };
  },
};

export default BuoyClient;
