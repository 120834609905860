import React from 'react';
import { RouteMap } from 'constants/Routes';
import HealthCareWorkerSwabbingIndividual from '../svg/HealthCareWorkerSwabbingIndividual';
import HealthCareOptions from '../svg/HealthCareOptions';
import PersonThinkingWithCrossBandaids from '../svg/PersonThinkingWithCrossBandaids';
import { TrendingArticle } from './types';
import getDefaultArticleUrl from 'utils/getDefaultArticleUrl';
import { Language } from 'hooks/useLanguage';

type Options = {
  labelFormat?: string;
};

function template(format: string) {
  return (dict: Record<string, string> = {}) => {
    return Object.keys(dict).reduce((acc, key) => {
      return acc.replace(`{{${key}}}`, dict[key]);
    }, format);
  };
}

export const getNavBarLinkLabel = (label: string, options?: Options) => {
  const { labelFormat } = options || {};

  return template(labelFormat || 'View all {{label}} articles')({
    label: label.toLowerCase(),
  });
};

const DEFAULT_IMAGE_SET = [
  HealthCareWorkerSwabbingIndividual,
  HealthCareOptions,
  PersonThinkingWithCrossBandaids,
];

export const RandomDefaultImage = ({ index }: { index: number }) => {
  const RandomImage = DEFAULT_IMAGE_SET[index];
  return <RandomImage />;
};

export const getTrendingArticleLink = ({
  article,
}: {
  article: TrendingArticle;
}) => {
  const { frozen, type, articleType, language } = article;
  if (type?.includes('blog')) {
    if (!frozen) {
      // For trending blog posts, the link should go to /category/slug if target page isn't frozen
      return `${article.category?.slug || ''}${article.slug}`;
    }
    return `${RouteMap.BLOG.base}${article.category?.slug || ''}${article.slug}`;
  }

  return getDefaultArticleUrl({
    slug: article.slug,
    language,
    articleType,
  });
};

// We show virtual & primary care OMD links under the "Find care" nav option, which links to /providers
export const shouldShowOMDLinks = (slug: string) =>
  slug?.includes(RouteMap.PROVIDERS.base);

export const getOMDLinkConfig = (language: Language) => [
  {
    url: 'https://optimalmd.buoyhealth.com/urgent-care',
    label: language.t('Nav.virtualUrgentCare'),
  },
  {
    url: 'https://optimalmd.buoyhealth.com/access-plus',
    label: language.t('Nav.virtualPrimaryCare'),
  },
];
