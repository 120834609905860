import BuoyClient from 'lib/BuoyClient';
import { GeocodeResponse } from 'types';

export type GeolocationAction =
  | {
      type: 'FETCH_USER_LOCATION';
      payload: Promise<GeocodeResponse | void>;
    }
  | {
      type: 'FETCH_USER_LOCATION_PENDING';
    }
  | {
      type: 'FETCH_USER_LOCATION_REJECTED';
      payload: Error;
    }
  | {
      type: 'FETCH_USER_LOCATION_FULFILLED';
      payload?: GeocodeResponse;
    };

export const fetchUserLocation = (): GeolocationAction => ({
  type: 'FETCH_USER_LOCATION',
  payload: BuoyClient.getBrowserLocation().catch(console.error),
});
