import React from 'react';
import { ThemeProvider } from '@material-tailwind/react';
import App, {
  AppInitialProps,
  AppContext,
  AppProps,
  NextWebVitalsMetric,
} from 'next/app';
import Head from 'next/head';
import Router from 'next/router';
import Script from 'next/script';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import { connect } from 'react-redux';
import { Store } from 'redux';
import {
  TIMING_EVENTS,
  addTiming,
  initRum,
  initializeSnowplowTracker,
  trackPageView,
} from 'analytics';
import AdShield from 'components/AdShield';
import Layout from 'components/Layout';
import { AWS_RUM_ENABLED, SENTRY_ENABLED } from 'constants/EnvVars';
import { GOOGLE_TAG_MANAGER_ID } from 'constants/Global';
import PageContext from 'contexts/PageContext';
import { reduxWrapper } from 'store';
import ConsentManagement from 'styled/components/ConsentManagement';
import GoogleConsentDefault from 'styled/components/ConsentManagement/GoogleConsentDefault';
import isProd from 'utils/isProd';

// Fix for changing routes with buoy redirect chrome extension
import 'utils/buoyInternalPushState';
import { initialize as initializeSentry } from 'utils/sentry';

import 'what-input';
import 'styles/App.scss';

import type { ApplicationContext, GlobalState } from 'types';

if (SENTRY_ENABLED) {
  initializeSentry();
}

if (AWS_RUM_ENABLED && typeof window !== 'undefined') {
  try {
    initRum();
    console.log('AWS RUM enabled.');
  } catch (err) {
    console.error(err);
    console.log('AWS RUM disabled.');
  }
}

class AppContainer extends App<
  AppInitialProps & ReturnType<typeof mapStateToProps>
> {
  queryClient: QueryClient;

  constructor(props: AppProps & ReturnType<typeof mapStateToProps>) {
    super(props);

    // Initializing a React-Query client in _app.tsx ensures that data is not
    // shared between different users and requests, while still only creating
    // the QueryClient once per component lifecycle.
    this.queryClient = new QueryClient();
  }

  /*
   * SR-840: Fixes a bug in which clicking the back button on certain pages
   * only changes the URL in the browser (window.location.href),
   * but doesn't trigger a proper router event to navigate to the previous page
   *
   * When the back button is clicked, forceRouteChange() checks if
   * there is a mismatch between the URL and router.asPath before
   * forcing the route change.
   *
   * Make sure to use the full url (window.location.href) which includes
   * search and anchor params
   */
  forceRouteChange() {
    const fullUrlExcludingOrigin = `${window.location.href.slice(
      window.location.origin.length,
    )}`;

    if (Router.asPath !== fullUrlExcludingOrigin) {
      Router.push(fullUrlExcludingOrigin);
    }
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      initializeSnowplowTracker();

      trackPageView();
    }

    Router.events.on('routeChangeStart', () => {
      /*
       * This is needed for the focus to be moved back to the beginning of the page after
       * client-side routing by next-router, we'll set it programmatically and remove on blur
       * because we don't want to focus the body if the user clicks on some parts of the body
       * that are actually not clickable.
       *
       * This is useful for click+tab interaction, where you click close to a link + tab in order
       * to focus that link – a fast way for moving the focus exactly where you want it to be.
       */
      document.body.setAttribute('tabIndex', '-1');
    });

    document.body.addEventListener('blur', () => {
      /*
       * Having tabIndex -1 present on body is not recommended, because it breaks the click+tab interaction.
       * This interaction means clicking close to a link anywhere on the page and then pressing tab, the link will receive focus.
       * If body has tabIndex -1, it will receive focus when clicking the body.
       */
      document.body.removeAttribute('tabIndex');
    });

    // Track Snowplow events on all route changes
    Router.events.on('routeChangeComplete', () => {
      trackPageView();
    });

    window.addEventListener('popstate', this.forceRouteChange);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.forceRouteChange);
  }

  public static getInitialProps = async ({
    ctx,
  }: AppContext): Promise<AppInitialProps> => {
    const { req, res, store } = ctx as ApplicationContext;

    // 2021-03-16: For /404, we have to return HTTP 200, because of current
    // limitation with our infrastructur., refer to
    // https://buoyhealth.atlassian.net/browse/SR-741
    if (req?.url === '/404' && res) {
      res.statusCode = 200;
    }
    // Avoid calling initializeApplication twice
    // TODO: Add other static rendered pages here
    if (req?.url?.startsWith('/learn')) {
      return { pageProps: {} };
    }

    const initializeApplication = (
      await import('state/actions/applicationActions')
    ).initializeApplication;

    return (store as Store)
      .dispatch(initializeApplication(ctx as ApplicationContext) as any)
      .then(() => {
        return {
          pageProps: {},
        };
      });
  };

  public render() {
    const { Component, pageProps } = this.props;
    // Workaround for https://github.com/vercel/next.js/issues/8592
    const err = (this.props as any).err;
    const modifiedPageProps = { ...pageProps, err };

    const loadGoogleTagManager = isProd();

    return (
      <>
        <Head>
          <meta name="viewport" content="initial-scale=1, viewport-fit=cover" />
          <link rel="icon" href="/favicon.png" />
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          <link
            rel="preconnect"
            href="https://www.googletagmanager.com"
            crossOrigin=""
          />
          <link
            rel="preload"
            href="/fonts/GT-Eesti-Display-Regular.woff2"
            as="font"
            crossOrigin=""
          />
          <link
            rel="preload"
            href="/fonts/GT-Eesti-Display-Bold.woff2"
            as="font"
            crossOrigin=""
          />
          <link
            rel="preload"
            href="/fonts/GT-Eesti-Display-Regular-Italic.woff2"
            as="font"
            crossOrigin=""
          />
          <link
            rel="preload"
            href="https://fonts.gstatic.com/s/spectral/v13/rnCs-xNNww_2s0amA9vmtm3BafaPWnII.woff2"
            as="font"
            type="font/woff2"
            crossOrigin="anonymous"
          />
          <link
            rel="preload"
            href="https://fonts.gstatic.com/s/spectral/v13/rnCr-xNNww_2s0amA9M5knjsS_ul.woff2"
            as="font"
            type="font/woff2"
            crossOrigin="anonymous"
          />
          <script
            defer
            dangerouslySetInnerHTML={{
              __html: `function isIE() {
                ua = navigator.userAgent;
                /* MSIE used to detect old browsers and Trident used to newer ones*/
                var is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;

                return is_ie;
              }
              /* Create an alert to show if the browser is IE or not */
              if (isIE()){
                alert('This browser is not supported');
              }`,
            }}
          />
        </Head>

        <GoogleConsentDefault />

        {/* -------------- Start Google Tag Manager -------------- */}
        {loadGoogleTagManager && (
          <>
            <Script
              data-cookieconsent="ignore"
              id="google-tag-manager"
              strategy="afterInteractive"
            >
              {`
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${GOOGLE_TAG_MANAGER_ID}');
              `}
            </Script>
          </>
        )}
        {/* -------------- End Google Tag Manager -------------- */}

        <QueryClientProvider client={this.queryClient}>
          <Hydrate state={pageProps.dehydratedState}>
            <PageContext.Provider value={pageProps}>
              <AdShield />
              <ThemeProvider>
                <Layout>
                  <Component {...modifiedPageProps} />
                  <ConsentManagement />
                </Layout>
              </ThemeProvider>
            </PageContext.Provider>
          </Hydrate>
        </QueryClientProvider>
      </>
    );
  }
}

// Ref: https://nextjs.org/docs/advanced-features/measuring-performance#custom-metrics
export function reportWebVitals(metric: NextWebVitalsMetric) {
  switch (metric.name) {
    case 'Next.js-hydration':
      addTiming(TIMING_EVENTS.NEXTJS_HYDRATION);

      break;
    case 'Next.js-render':
      addTiming(TIMING_EVENTS.NEXTJS_RENDER);

      break;
    case 'Next.js-route-change-to-render':
      addTiming(TIMING_EVENTS.NEXTJS_ROUTE_CHANGE_TO_RENDER);

      break;
    default:
      break;
  }
}

function mapStateToProps(state: GlobalState) {
  return {
    store: {
      featureFlags: state.featureFlags,
    },
  };
}

export default reduxWrapper.withRedux(connect(mapStateToProps)(AppContainer));
