import { FC } from 'react';
import {
  ICareCollectionResource,
  ICareCollectionResourceHours,
} from '@buoyhealth/common.buoy-types';
import type { TabProps } from '@mui/material';
import { PortableTextBlock } from '@portabletext/types';
import type { BrowserTracker } from '@snowplow/browser-tracker';
import type { LDFlagSet } from 'launchdarkly-node-server-sdk';
import { NextApiRequest, NextApiResponse, NextPageContext } from 'next/types';
import { Store } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import { TIMING_EVENTS } from 'analytics';
import {
  CalloutColor,
  EmphasizeModuleColor,
  GeneralModuleColor,
  SplitCtaColor,
} from 'constants/ColorScheme';
import { ApplicationAction } from 'state/actions/types/applicationActionsTypes';
import { ApplicationUIAction } from 'state/actions/types/applicationUIActionsTypes';
import { ArticleAction } from 'state/actions/types/articleActionsTypes';
import { AuthorPageAction } from 'state/actions/types/authorPageActionsTypes';
import { BlogAction } from 'state/actions/types/blogActionsTypes';
import { CampaignPageAction } from 'state/actions/types/campaignPageActionsTypes';
import { DrugPageAction } from 'state/actions/types/drugPageActionsTypes';
import { FeatureFlagsAction } from 'state/actions/types/featureFlagActionsTypes';
import { FormAction } from 'state/actions/types/formActionsTypes';
import { GenericPageAction } from 'state/actions/types/genericPageActionsTypes';
import { GlobalSettingsAction } from 'state/actions/types/globalSettingsActionsTypes';
import { I18nActions } from 'state/actions/types/i18nActionsTypes';
import { ShowcasePageAction } from 'state/actions/types/showcasePageActionsTypes';
import { SurveyAction } from 'state/actions/types/surveyActionsTypes';
import { ApplicationUIReducer } from 'state/reducers/applicationUI';
import { ArticleReducer } from 'state/reducers/article';
import { AuthorPageReducer } from 'state/reducers/authorPage';
import { BlogReducer } from 'state/reducers/blog';
import { BrowsePageAction } from 'state/actions/types/browsePageActionTypes';
import { BrowseReducer } from 'state/reducers/browsePage';
import { CampaignPageReducer } from 'state/reducers/campaignPage';
import { DrugPageReducer } from 'state/reducers/drugPage';
import { FeatureFlagsReducer } from 'state/reducers/featureFlags';
import { GenericPageReducer } from 'state/reducers/genericPage';
import { GeolocationAction } from 'state/actions/geolocationActions';
import { GeolocationReducer } from 'state/reducers/geolocation';
import { GlobalSettingsReducer } from 'state/reducers/globalSettings';
import { IDrugLink } from 'state/sanitizers/sanitizeDrugLink';
import { ShowcasePageReducer } from 'state/reducers/showcasePage';
import { StatusReducer } from 'state/reducers/status';
import { UserResearchReducer } from 'state/reducers/userResearch';
import { Color } from 'styled/theme/colors';
import { FontSizes, Fonts } from 'styled/theme/typography';
import { ITypeformModule } from 'components/modules/TypeformModule';
import { TrendingArticle } from 'styled/components/Navigation/types';
import type { ISupplierCtaModule } from 'styled/components/modules/SupplierCtaModule/types';
import type { I18nReducer } from 'state/reducers/i18n';
import type {
  IInterviewQuestion,
  ISupplierCardConfig,
  MiniInterviewType,
  TreatmentSupplierIcon,
} from '@buoy-components/polaris/types';

import type { RampGlobal } from 'components/Playwire/Ramp';
import type { ProvidersSummary } from 'types/api/documents/providers';

declare global {
  interface Window {
    _pwGA4PageviewId?: unknown;
    bh_supplier_cta_module_uuid?: string;
    dataLayer: {
      push: (eventData: GoogleTagManagerTrackingEventData) => void;
    };
    fbq?: {
      (
        eventType: 'track',
        eventName: 'Lead',
        parameters?: {
          value?: number;
          currency?: string;
          content_name?: string;
          content_category?: string;
        },
      ): void;
    };
    ga?: any;
    gm_authFailure?: () => void;
    google_optimize: {
      get: (experimentId: string) => string;
    };
    google_tag_manager?: unknown;
    gtag?: any;
    ramp?: RampGlobal;
    ChiliPiper?: {
      submit: (
        subdomain: string,
        router: string,
        options: {
          lead: FormValues;
          // Allows us to add styling to the loading of the CP calendar
          injectRootCss: boolean;
          // Maps data to Salesforce fields
          map: boolean;
          // Callback after lead has been sent to CP and matched to a queue and calandar is shown
          onRouted: Function;
          // Callback when lead is submitted but can't be matched to a queue
          onError: Function;
        },
      ) => void;
    };
    Cookiebot?: {
      // https://www.cookiebot.com/en/developer#h-properties
      consent: Readonly<{
        marketing: boolean;
        method?: 'implied' | 'explicit';
        necessary: boolean;
        preferences: boolean;
        statistics: boolean;
      }>;
      consented: Readonly<boolean>;
      declined: Readonly<boolean>;
      doNotTrack: Readonly<boolean>;
      hasResponse: Readonly<boolean>;
      regulations: Readonly<{
        ccpaApplies: boolean;
        gdprApplies: boolean;
        igpdApplies: boolean;
      }>;

      // https://www.cookiebot.com/en/developer#h-methods
      getScript: (url: string, async?: boolean, callback?: () => void) => void;
      hide: () => void;
      renew: () => void;
      runScript: () => void;
      show: () => void;
      submitCustomConsent: (
        preferences: boolean,
        statistics: boolean,
        marketing: boolean,
      ) => void;
      withdraw: () => void;

      // https://support.cookiebot.com/hc/en-us/articles/360019954974-Using-geolocation-to-change-banner-consent-method
      dialog: {
        consentLevel?: 'implied' | 'strict';
        detachOnscrollEvent: () => void;
        setOnscrollEvent: () => void;
      };
      userCountry: Readonly<string>;
    };
    Cypress?: unknown;
    SNOWPLOW_TRACKERS?: Partial<Record<string, BrowserTracker>>;
    TIMING_EVENTS?: Partial<Record<TIMING_EVENTS, number>>;
    SNOWPLOW_EVENTS?: Partial<Record<string, number>>;
  }
}

export type Action =
  | ApplicationAction
  | ApplicationUIAction
  | ArticleAction
  | AuthorPageAction
  | BlogAction
  | DrugPageAction
  | FeatureFlagsAction
  | FormAction
  | GenericPageAction
  | GlobalSettingsAction
  | I18nActions
  | CampaignPageAction
  | ShowcasePageAction
  | HydrateAction
  | SurveyAction
  | GeolocationAction
  | BrowsePageAction;

export interface AccordionListItem {
  id: string;
  button: {
    label: string;
    description: string;
  };
  content: PortableText;
}

export interface AccordionListModule {
  type: 'accordionList';
  id: string;
  moduleHeader: ModuleHeader;
  listItems: AccordionListItem[];
  button: {
    label: string;
    url: string;
  };
  moduleOptions: ModuleOptions;
  bottomDescription: PortableText;
}

export interface AnimationAndTextModule {
  type: 'animationAndTextModule';
  id: string;
  header: string;
  description: PortableText;
  button: {
    label: string;
    url: string;
  };
  lottie: Lottie;
  image: Image;
}

export interface AccordionListWithBlueAccentsModule {
  type: 'accordionListWithAnimation';
  id: string;
  header: string;
  listItems: AccordionListItem[];
}

export interface AffiliateProduct {
  token: string;
  identifier: string;
  name: string;
  description: string | null;
  image_url: string | null;
  price: number | null;
  rating: number | null;
  review_count: number | null;
  review_link: string | null;
  affiliate_url: string;
  source: string;
  feature_bullets: string[];
  buoy_page_urls: string[];
}
export interface AffiliateProductsResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: AffiliateProduct[];
}

export type ApplicationContext = NextPageContext & {
  query: Record<string, unknown>;
  req: NextApiRequest;
  res: NextApiResponse;
  store: Store;
};

export interface Article {
  id: string;
  title: string;
  slug: string;
  sxOverview: ArticleOverview[];
  type: 'article'; // Sanity document type
  articleType: ArticleType | null; // Article type
  meta: ArticleMeta;
  hero: ArticleHero;
  conditionName: string;
  content: ArticleContent;
  language?: string;
  seo: ArticleSeoSettings;
  taxonomy: string[];
  testerRecruitment: TesterRecruitment;
  pauseUxCta: PauseUxCtaSymptomChecker | PauseUxCtaBuoyServices;
  symptomChecker: ArticleSymptomChecker;
  firstAidBox: FirstAidBox;
  // TODO: Replace with treatment page type from SR-703
  treatmentsPage: {
    _id: string;
    _type: 'treatmentsPage';
    careOverview: CareOverview[];
  } | null;
  browsePageLinks: IBrowsePageLink[];
  translatedPages?: TranslatedPageReference[];
  disableAds: boolean;
}

export type ArticleBodyModule =
  | AccordionListModule
  | AccordionListWithBlueAccentsModule
  | AnimationAndTextModule
  | ArticleButton
  | ArticleText
  | CarouselModule
  | CtaModule
  | IEmbeddedInterview
  | EmphasisLinkCardModule
  | IFeaturedLinkModule
  | FirstAidBoxModule
  | IGoogleFormModule
  | IInsuranceFormModule
  | HighlightedImagesModule
  | HighlightedText
  | ImageCardsModule
  | IKatalysModule
  | LargeTextModule
  | LinkCardsModule
  | LinkCardWithImageModule
  | LinksListModule
  | ListItemsAndLogosModule
  | NumberedListWithImageModule
  | IQueryModule
  | QuoteModule
  | DxListModule
  | RoundedBgLinkCardsModule
  | SliderModule
  | SingleBlockCtaModule
  | SplitCtaModule
  | ISupplierCtaModule
  | TesterRecruitmentCtaModule
  | ThreeColumnAnimationModule
  | ITypeformModule
  | VideoModule
  | VideoAndListItemsModule
  | VideoCtaModule
  | ImageWithCaptionModule
  | HighlightsModule
  | SupplierCards
  | ProductCards
  | null;

export interface ArticleButton {
  type: 'articleButton';
  id: string;
  sideNav: ContentAnchor;
  label: string;
  link: string;
  logoIsActive: boolean;
}

export interface ArticleCallout {
  id: string;
  type: 'articleCallout';
  color: CalloutColor;
  infoSectionText: PortableText;
  supportingSectionText: PortableText;
  image: Image;
  icon: IconConfig;
}

export type ArticleItem = ArticleLink;

export interface ArticleContent {
  articleUserStories: ArticleUserStories;
  overviewSlug: string;
  overviewLabel: string;
  overview: PortableText;
  promoteSupplier?: boolean;
  bodyModules: ArticleBodyModule[];
  references: PortableText;
  moreArticles: (ArticleLink | null)[];
  articleRating: ArticleRating;
  hideUserStory: boolean;
}

export interface ArticleGenericPage {
  id: string;
  type: 'articleGenericPage';
  title: string;
  slug: string;
  showIntercom: boolean;
  bodyModules: ArticleBodyModule[];
  date: string;
  disableAds: boolean;
}

export interface ArticleHero {
  image: Image;
  subtitle: string;
  color: EmphasizeModuleColor;
}

export interface ArticleImage {
  id: string;
  type: 'articleImage';
  isFullWidth: boolean;
  caption: string;
  desktopImage: Image;
  mobileImage: Image;
}

export interface ArticleLink {
  id: string;
  type: 'article';
  articleType: ArticleType | null;
  conditionName: string;
  modifiedDate: string;
  title: string;
  slug: string;
  heroImage: Image;
  heroColor: string;
  subtitle: string;
  moduleTitle: string;
  moduleDescription: string;
  moduleImage: Image;
  seo: ArticleSeoSettings;
  meta: {
    author: Author | null;
    reviewer: Author | null;
  };
  symptomChecker: SymptomChecker;
  linkedArticle?: ArticleLink | null;
  customTitle?: string | null;
  taxonomy: string[];
  browsePageLinks: IBrowsePageLink[];
  language: string;
}

export interface ArticleRating {
  likes: number;
  dislikes: number;
}

export interface ArticleVideo extends Video {
  id: string;
  type: 'articleVideo';
  isFullWidth: boolean;
  caption: string;
}

export interface ArticleMeta {
  author: Author | null;
  reviewer: Author | null;
  publishedDate: string;
  modifiedDate: string;
}

export interface ArticleSeoSettings extends SeoSettings {
  keywords: string[];
  noindex: boolean;
}

export interface ArticleSymptomChecker extends SymptomChecker {
  header: string;
  description: string;
}

export interface ArticleText {
  type: 'articleText';
  id: string;
  sideNav: ContentAnchor;
  addToSeoFaqJsonSchema: boolean;
  text: PortableText;
  contentTag: ContentTag;
  title: string;
}

export enum ArticleType {
  Sx = 'Sx',
  Dx = 'Dx',
  Cost = 'Cost',
  Other = 'Other',
}

export interface ArticleUserStory {
  articleId: string;
  story: string;
  name: string;
  title: string;
  id: string;
  date: string;
  likes: number;
  pageNumber: number;
}

export interface ArticleUserStories {
  totalCount: number;
  lastFetchedPageNumber: number;
  paginatedStories: {
    [pageNumber: number]: ArticleUserStory[];
  };
  storiesById: {
    [id: string]: ArticleUserStory;
  };
}

export interface Asset {
  id: string;
  alt: string;
}

export interface Author extends TeamMember {
  honorifics: string;
  specialty: string;
  bio: PortableText;
  links: ExternalLink[];
  educationAndTraining: EducationInfo[];
  practiceNames: PracticeName[];
  certifications: PortableText;
  awards: PortableText;
  publications: PortableText;
}

export interface AuthorPage extends Author {
  articles: (ArticleLink | null)[];
}

export interface BannerModule {
  type: 'banner';
  id: string;
  title: string;
  category: string;
  date: string;
  buttonLabel: string;
  buttonLink: string;
  image: Image;
}

export interface BlogPost {
  id: string;
  categories: string[];
  disableAds: boolean;
  frozen: boolean;
  heroImage: Image;
  language: string;
  meta: BlogPostMeta;
  modules: BlogPostModule[];
  references: PortableText;
  seo: SeoSettings;
  slug: string;
  title: string;
  type: 'blogPost';
}

export interface IBlogCategoryPage {
  id: string;
  type: 'blogCategory';
  title: string;
  slug: string;
  hero: BrowsePageHero;
  blogPosts: Array<BlogPost>;
  blogPostCount: number;
  seo: SeoSettings;
  modules: BlogPostModule[];
}

export interface BlogPostLink {
  id: string;
  type: 'blogPost';
  title: string;
  slug: string;
}

export interface BlogPostMeta {
  author: Author | null;
  publishedDate: string;
  updatedDate: string;
}

export type BuyingGuideModule = {
  anchor?: string;
  buyingFactors?: PortableText[];
  conclusion?: PortableText;
  id: string;
  introduction?: PortableText;
  title: string;
  type: 'buyingGuide';
};

export type BlogPostModule =
  | BuyingGuideModule
  | CtaModule
  | DataSetVisualsModule
  | HighlightsModule
  | IEmbeddedInterview
  | IFeaturedLinkModule
  | IGoogleFormModule
  | IInsuranceFormModule
  | IKatalysModule
  | ImageWithCaptionModule
  | InterviewModule
  | ISupplierCtaModule
  | NumberedListWithImageModule
  | QuoteModule
  | TextModule
  | ThreeColumnInfoModule
  | VideoModule
  | ProsAndConsList
  | ProductCards
  | SupplierCards
  | null;

export type LicenseList = { type: string; displayName: string }[];

export type TagList = { token: string; name: string }[];

export type CareResourceLocation = {
  city: string;
  state: string;
  streetNumber: string;
  streetName: string;
  buildingUnit: string;
  postalCode: string;
  country: string;
  latitude: number | null;
  longitude: number | null;
  token: string;
};

export type CareCollectionResource = {
  acceptingWalkInPatients: boolean;
  actionTitle: string;
  allProvidersLicensed: boolean;
  averageYearsOfExperience: number;
  careCollectionResourceToken: string;
  ctaType: 'url' | 'phone' | 'easy_booking' | null;
  distance: number | null;
  hasMobileApp: boolean;
  highlights: { text: string }[];
  hours: ICareCollectionResourceHours[];
  icon: Image;
  insurance: string;
  insuranceAccepted: boolean | null;
  insurancePlans: string[];
  isFree: boolean;
  languageServices: { key: string; service: string }[];
  languages: { code: string; language: string }[];
  licenses: LicenseList;
  location: CareResourceLocation;
  locations: { states: string[]; postalCodes: string[] };
  maxAge: number | null;
  medium: ICareCollectionResource['medium'];
  minAge: number | null;
  name: string;
  newPatientWaitTime: string;
  openNow: boolean;
  phone: string;
  pricing: string;
  redirectField: ICareCollectionResource['redirect_field'] | '';
  sexAtBirthQualification:
    | ICareCollectionResource['sex_at_birth_qualification']
    | '';
  summary: string;
  tags: TagList;
  timeZone: ICareCollectionResource['time_zone'];
  timeZoneIana: ICareCollectionResource['time_zone_iana'];
  title: string;
  url: string;
  website: string;
};

export interface ArticleOverview {
  enabled: boolean;
  sectionName: string;
  content: PortableText;
}

export interface CareOverview extends ArticleOverview {
  shopPage?: {
    url?: string;
    linkText?: string;
  };
  shopPageCategory?: string;
}

export enum CareOverviewSection {
  EMERGENCY_CARE = 'emergencyCare',
  SYMPTOM_RELIEF = 'symptomRelief',
  WHAT_TO_DO_FIRST = 'whatToDoFirst',
  WHEN_TO_SEE_PROVIDER = 'whenToSeeProvider',
}

export interface CareResourceTag {
  name: string;
  token: string;
  count: number;
}

export interface CareResourceResponse {
  count: number;
  next: string | null;
  prev: string | null;
  /** Sanitized data */
  resources: CareCollectionResource[];
  resourcesByTag: Record<string, CareCollectionResource[]>;
  tags: CareResourceTag[];
}

export interface CarouselModule {
  type: 'carousel';
  id: string;
  moduleHeader: ModuleHeader;
  moduleOptions: ModuleOptions;
  carouselItems: CarouselItem[];
}

export type CarouselItem =
  | ArticleItem
  | ImageItem
  | QuoteItem
  | TextItem
  | VideoItem
  | null;

export interface CarouselItemOption {
  id: string;
  button: Button;
  description: string;
  subtitle: string;
  title: string;
}

export interface ContentAnchor {
  id: string;
  label: string;
  anchor: string;
}

export interface ContentHubCategory {
  id: string;
  title: string;
  slug: string;
  order: string;
  parentCategoryId: string;
  hero: PageHero;
  modules: Module[];
}

export interface Button {
  label: string;
  link: string;
}

export interface Callout {
  isActive: boolean;
  color: CalloutColor;
  infoSectionIsActive: boolean;
  variant: 'right' | 'left' | 'bottom';
  infoSectionImage: Image;
  infoSectionText: PortableText;
  supportingSectionImage: Image;
  supportingSectionText: PortableText;
}

export interface CampaignPage {
  id: string;
  type: 'campaignPage';
  title: string;
  slug: string;
  description: PortableText;
  image: Image;
  showNameAndEmailFieldsOnly: boolean;
  formTitle: string;
  formSubmissionMethod: WebToLeadSubmissionMethod;
  formLeadSource: string;
  formLeadSourceOtherC: string;
  formCampaignId: string;
  formCustomButtonLabel: string;
  formCustomButtonUrl: string;
  redirectUrl: string;
  demoVideo: Video;
  demoVideoMessage: string;
  showIntercom: boolean;
  enableFacebookPixel: boolean;
  seo: SeoSettings;
}

export interface CtaModule {
  type: 'cta';
  id: string;
  text: PortableTextBlock[];
  image?: Image;
  desktopImageMaxWidth: number | null;
  moduleOptions?: ModuleOptions;
}

export interface DataSetVisualsModule {
  type: 'dataSetVisuals';
  id: string;
  moduleTitle: string;
  disclaimer: PortableText;
  dataDescription: PortableText;
  dataList: {
    id: string;
    name: string;
    percentage: number;
    description: string;
    section: string;
  }[];
  additionalDataLabel: string;
  additionalData: PortableText;
  moduleOptions: ModuleOptions;
}

export type Dispatch = ThunkDispatch<GlobalState, undefined, Action>;

export type DrawerSections = Record<
  string,
  {
    label: string;
    content: PortableText;
  }
>;

export interface DropdownOption {
  value: string;
  label: string;
}

export interface DrugBasics {
  descriptionTitle: string;
  description: PortableText;
  genericOrBrandName: string;
  nameLabel: 'generic' | 'brand';
  prescriptionRequirement: PortableText;
  warning: PortableText;
}

export interface DrugHowTo {
  whenAndHow: PortableText;
  dos: PortableText;
  donts: PortableText;
}

export interface DrugInteractions {
  interactionsWithOtherDrugs: PortableText;
  interactionsWithPreExistingConditions: PortableText;
}

export interface DrugPage {
  id: string;
  type: 'drug';
  name: string;
  slug: string;
  pronunciation: string;
  headline: string;
  author: Author | null;
  readingTime: number;
  publishedDate: string;
  modifiedDate: string;
  pricingUrl: string;
  relatedDrugs: {
    id: string;
    name: string;
    slug: string;
  }[];
  relatedDiagnoses: ArticleLink[];
  heroColor: EmphasizeModuleColor;
  overview: PortableText;
  dosage: PortableText;
  dosageTitle: string;
  similarDrugs: PortableText;
  basics: DrugBasics;
  howToTakeIt: DrugHowTo;
  sideEffects: DrugSideEffects;
  longTermComplications: PortableText;
  safetyNotes: PortableText;
  additionalSafetyNotes: PortableText;
  interactions: DrugInteractions;
  references: PortableText;
  reviews: DrugReviews;
  seo: ArticleSeoSettings;
  ratings: {
    likes: number;
    dislikes: number;
  };
  disableAds: boolean;
}

export type DrugReview = {
  id: string;
  drugPageId: string;
  drugRating: number;
  description: string;
  duration: DrugReviewDuration;
  firstNameInitial: string;
  sideEffectManagement: string;
  sideEffects: {
    label: string;
    value: string;
  }[];
  submittedDate: string;
  // Denotes page number in reviews reducer
  pageNumber: number;
  ratings: {
    likes: number;
  };
};

export type DrugReviews = {
  totalCount: number;
  lastFetchedPageNumber: number;
  paginatedReviews: {
    [pageNumber: number]: DrugReview[];
  };
  reviewsById: {
    [id: string]: DrugReview;
  };
};

export enum DrugReviewDuration {
  LESS_THAN_SEVEN_DAYS = 'less-than-7-days',
  ONE_TO_TWO_WEEKS = '1-to-2-weeks',
  THREE_TO_FOUR_WEEKS = '3-to-4-weeks',
  MORE_THAN_A_MONTH = 'more-than-a-month',
  MORE_THAN_SIX_MONTHS = 'more-than-6-months',
}

export enum DrugReviewKeys {
  ID = 'id',
  RATING = 'rating',
  DESCRIPTION = 'description',
  DURATION = 'duration',
  FIRST_NAME_INITIAL = 'firstNameInitial',
  EMAIL = 'email',
  SIDE_EFFECT_MANAGEMENT = 'sideEffectManagement',
  SIDE_EFFECTS = 'sideEffects',
}

export type DrugReviewFormBody = {
  [DrugReviewKeys.ID]: string;
  [DrugReviewKeys.RATING]: number;
  [DrugReviewKeys.DESCRIPTION]: string;
  [DrugReviewKeys.DURATION]: DropdownOption | null;
  [DrugReviewKeys.FIRST_NAME_INITIAL]: string;
  [DrugReviewKeys.EMAIL]: string;
  [DrugReviewKeys.SIDE_EFFECT_MANAGEMENT]: string;
  [DrugReviewKeys.SIDE_EFFECTS]: DropdownOption[];
};

export type DrugSideEffectUgc = {
  name: string;
  count: number;
  reviews: number;
};

export interface DrugSideEffects {
  sideEffectsDiscussWithDoctor: PortableText;
  sideEffectsFdaData: {
    type: 'qualitative' | 'quantitative' | null;
    qualitativeCommon: PortableText;
    qualitativeSerious: PortableText;
    quantitative: DrugSideEffectQuantitativeFdaData[];
  };
  fullListSideEffects: PortableText;
  ugc: DrugSideEffectUgc[];
}

export interface DrugSideEffectQuantitativeFdaData {
  id: string;
  sideEffectNameSingular: string;
  sideEffectNamePlural: string;
  percentage: number;
}

export interface HighlightedImagesModule {
  type: 'highlightedImages';
  id: string;
  title: string;
  text: PortableText;
  frontImage: Image;
  backImage: Image;
}

export interface DynamicRoute {
  as: string;
  href: string;
}

export interface EducationInfo {
  id: string;
  details: string;
  logo: Image;
}

export interface ErrorPage {
  title: string;
  description?: string;
  link?: {
    label: string;
    url: string;
  };
  image?: Image;
}

export interface EmphasisLinkCardModule {
  type: 'emphasisLinkCard';
  id: string;
  content: LinkCard;
  contentTitleAlignment: 'left' | 'center';
  moduleOptions: ModuleOptions;
}

export interface ExternalLink {
  id: string;
  label: string;
  url: string;
}

/*
Launch Darkly Environments:
 - development: local
 - staging: Vercel
*/
export type FeatureFlags = LDFlagSet;

export interface FeatureFlagsContext {
  req: NextApiRequest;
  query: Record<string, unknown>;
}

export interface FirstAidBox {
  title: string;
  description: PortableText;
  backgroundColor: string;
  thinkSectionTitle: string;
  thinkSectionDescription: PortableText;
  checkSectionTitle: string;
  checkSectionDescription: PortableText;
  doSectionTitle: string;
  doSectionDescription: PortableText;
  urgentSectionTitle: string;
  urgentSectionDescription: PortableText;
  supportSectionTitle: string;
  supportSectionDescription: PortableText;
  ctaLabel: string;
  ctaUrl: string;
}

export interface FirstAidBoxModule {
  type: 'firstAidBox';
  id: string;
}

export interface Footer {
  social: ExternalLink[];
  menu: FooterMenu[];
  termsSlug: string;
  privacySlug: string;
  cookiesSlug: string;
  footerImage: FooterImage | null;
}

export interface FooterMenu {
  title: string;
  list: InternalLink[];
}

export interface FooterSocial {
  label: string;
  link: string;
}

export interface FooterImage {
  image: Image;
  alternateImage: Image;
  text: string;
}

export interface FormValues {
  [title: string]: string;
}

export interface FourColumnVideoModule {
  id: string;
  type: 'fourColumnVideo';
  moduleHeader: ModuleHeader;
  moduleOptions: ModuleOptions;
  contents: FourColumnVideoModuleItem[];
}

export interface FourColumnVideoModuleItem {
  id: string;
  title: string;
  vimeoId: string;
  image: Image;
  customPopUpTitle: string;
  description: string;
  buttonLabel: string;
}

export interface GoBackModule {
  id: string;
  type: 'goBack';
  mobileImage: Image;
  desktopImage: Image;
  backButtonLabel: string;
  moduleTitle: string;
  moduleDescription: PortableText;
  moreDetails: PortableText;
  ctaSectionText: PortableText;
  ctaButtonLabel: string;
  ctaButtonLink: string;
  ctaButtonVariant: 'symptom-checker-blue';
}

export interface InputField {
  label: string;
  title: string;
  isRequired: boolean;
  type: InputFieldType;
  options: string[];
  regex?: string;
}

export type InputFieldType =
  | 'email'
  | 'text'
  | 'tel'
  | 'number'
  | 'textarea'
  | 'date';

export interface GeneralInquiry {
  inputFields: InputField[];
  sectionTitle: string;
  sectionDescription: PortableText;
  formDescription: string;
}

export interface GeneralInquiryModule {
  id: string;
  type: 'generalInquiry';
  formSubmissionMethod: WebToLeadSubmissionMethod;
  formLeadSource: string;
  formLeadSourceOtherC: string;
}

export interface GenericPage {
  id: string;
  type: 'genericPage';
  title: string;
  slug: string;
  showIntercom: boolean;
  hero: PageHero;
  modules: Module[];
  seo: SeoSettings;
  disableAds: boolean;
}

export interface GeocodeResponse {
  latitude: number;
  longitude: number;
  postal?: string;
  city?: string;
  admin1?: string;
  admin1_code?: string;
  country?: string;
  country_code?: string;
}

export interface BuoyLocationResponse {
  country: string;
  'country-name': string;
  'country-region': string;
  'country-region-name': string;
  city: string;
  'postal-code': string;
  'time-zone': string;
  'metro-code': string;
  latitude: string;
  longitude: string;
}

export interface GlobalState {
  applicationUI: ApplicationUIReducer;
  article: ArticleReducer;
  authorPage: AuthorPageReducer;
  blog: BlogReducer;
  browsePage: BrowseReducer;
  campaignPage: CampaignPageReducer;
  drugPage: DrugPageReducer;
  featureFlags: FeatureFlagsReducer;
  genericPage: GenericPageReducer;
  geolocation: GeolocationReducer;
  globalSettings: GlobalSettingsReducer;
  i18n: I18nReducer;
  showcasePage: ShowcasePageReducer;
  status: StatusReducer;
  userResearch: UserResearchReducer;
}

export type GoogleTagManagerTrackingEventData = {
  event: string;
  name?: string;
  eventCallback?: Function;
  eventParameters?: Object;
};

export type PageHeroModule =
  | AccordionListModule
  | EmphasisLinkCardModule
  | NumberedListWithImageModule
  | SingleBlockCtaModule
  | TextModule
  | ThreeColumnInfoModule
  | null;

export interface HighlightedText {
  type: 'highlightedText';
  id: string;
  sideNav: ContentAnchor;
  text: PortableText;
}

export interface HighlightsModule {
  type: 'highlights';
  id: string;
  contents: {
    id: string;
    icon: Image;
    title: string;
    text: PortableText;
  }[];
  moduleOptions: ModuleOptionsGeneralColor;
}

interface HydrateAction {
  type: '__NEXT_REDUX_WRAPPER_HYDRATE__';
  payload: any;
}

export interface IconAndText {
  id: string;
  text: string;
  icon: Image;
}

export interface Image {
  id: string;
  src: string;
  alt: string;
  crop: ImageCrop | null;
  hotspot: object | null;
  metadata: {
    dimensions: ImageDimensions;
  } | null;
}

export interface ImageItem extends CarouselItemOption {
  type: 'imageItem';
  image: Image;
}

export interface ImageDimensions {
  aspectRatio: number;
  width: number;
  height: number;
}

export interface ImageCard {
  id: string;
  title: string;
  description?: string;
  image: Image;
}

export interface ImageCardsModule {
  type: 'imageCards';
  id: string;
  title: string;
  imageCards: ImageCard[];
  moduleOptions: ModuleOptions;
}

export interface ImageCrop {
  bottom?: number;
  left?: number;
  top?: number;
  right?: number;
}

export interface InternalLink {
  id: string;
  label: string;
  slug: string;
}

export interface InterviewDialog {
  id: string;
  type: 'interviewDialog';
  text: PortableText;
  speakerImage: Image;
}

export interface InterviewImage {
  id: string;
  type: 'interviewImage';
  image: Image;
  inlineImageWidth: number; // In percentage
  caption: string;
}

export interface InterviewModule {
  id: string;
  type: 'interview';
  contents: Array<InterviewImage | InterviewDialog | InterviewQuestion>;
  moduleOptions: ModuleOptions;
}

export interface InterviewQuestion {
  id: string;
  type: 'interviewQuestion';
  text: string;
  desktopFontSize: FontSizes.lg | FontSizes.md;
}

export interface JobPostingsModule {
  type: 'jobPostings';
  id: string;
  moduleHeader: ModuleHeader;
  button: {
    label: string;
    url: string;
  };
  moduleOptions: ModuleOptions;
  bottomDescription: PortableText;
}

export interface IconAndText {
  id: string;
  text: string;
  icon: Image;
}

export interface ImageWithCaptionModule {
  type: 'imageWithCaption';
  id: string;
  image: Image;
  caption: string;
  moduleOptions: ModuleOptions;
}

export interface InstagramFeedImageItem {
  id: string;
  src: string;
  link: string;
}

export interface IKatalysModule {
  id: string;
  type: 'katalysModule';
  url: string;
  utmParams: {
    medium: string;
    source: string;
  };
}

export interface LargeTextModule {
  type: 'largeText';
  id: string;
  text: string;
  desktopFontSize: FontSizes.xl | FontSizes.xxl;
  moduleOptions: ModuleOptions;
}

export interface LinksListItem {
  type: 'manualContent' | 'article';
  id: string;
  title: string;
  description: string;
  url: string;
  buttonLabel: string;
}

export interface LinksListModule {
  type: 'linksList';
  id: string;
  anchor: string;
  moduleOptions: ModuleOptions;
  moduleHeader: ModuleHeader;
  contents: LinksListItem[];
}
export interface LinkCard {
  id: string;
  title: string;
  description?: string;
  text?: PortableText;
  button: {
    ariaLabel?: string;
    label: string;
    url: string;
    variant: 'underline-white' | 'underline-black' | 'primary';
    gtmTrackerEventName: string;
  };
}

export interface LinkCardWithType extends LinkCard {
  type: string;
}

export interface LinkCardsModule {
  type: 'linkCards';
  id: string;
  linkCards: LinkCardWithType[];
  moduleOptions: ModuleOptions;
}

export interface LinkCardWithImageModule {
  type: 'linkCardWithImage';
  id: string;
  anchor: string;
  linkCard: LinkCard;
  image: Image;
  fillImageContainer: boolean;
  imageContainerBackgroundColor: EmphasizeModuleColor;
  desktopImagePosition: 'left' | 'right';
  desktopMaxHeight: number;
  moduleOptions: ModuleOptions;
}

export interface ListItemText {
  id: string;
  text: string;
}

export interface ListItemsAndLogosModule {
  type: 'listItemsAndLogosModule';
  id: string;
  header: string;
  listItems: IconAndText[];
  images: Image[];
}

export interface LogosModule {
  type: 'logos';
  id: string;
  moduleTitle: string;
  moduleDescription: PortableText;
  images: Image[];
  renderLogosGrayscale: boolean;
}

export type Lottie = {
  id: string;
  url: string;
  alt: string;
} | null;

export interface QuestionOptionChild {
  _key: string;
  isNOTA?: boolean;
  optionText: string;
}

export interface ThreeColumnAnimationModule {
  id: string;
  type: 'threeColumnAnimationModule';
  header: string;
  description: PortableText;
  items: {
    id: string;
    title: string;
    description: string;
    image: Image;
    lottie: Lottie;
  }[];
}

export interface IGoogleFormModule {
  id: string;
  url: string;
  height?: number;
  type: 'googleFormModule';
  width?: number;
  trustedFormEnabled?: boolean;
}

export interface IInsuranceFormModule {
  id: string;
  type: 'insuranceFormModule';
  trustedFormEnabled?: boolean;
}

export interface VideoCtaModule {
  id: string;
  type: 'videoCtaModule';
  headerText: string;
  headerIcon: Image;
  description: string;
  ctaButton: Button;
  video: Video;
}

/*
 * ModuleSwitch modules include modules from
 * GenericPageView
 */
export type Module =
  | AccordionListModule
  | AccordionListWithBlueAccentsModule
  | AnimationAndTextModule
  | CarouselModule
  | EmphasisLinkCardModule
  | FourColumnVideoModule
  | GeneralInquiryModule
  | GoBackModule
  | IGoogleFormModule
  | IInsuranceFormModule
  | ISupplierCtaModule
  | HighlightedImagesModule
  | ImageCardsModule
  | InstagramFeedModule
  | JobPostingsModule
  | LargeTextModule
  | LinkCardsModule
  | LinkCardWithImageModule
  | LinksListModule
  | ListItemsAndLogosModule
  | LogosModule
  | NumberedListWithImageModule
  | QuoteModule
  | SingleBlockCtaModule
  | SliderModule
  | SplitCtaModule
  | TeamListModule
  | ThreeColumnAnimationModule
  | ThreeColumnInfoModule
  | ThumbnailCardsModule
  | TextModule
  | ITypeformModule
  | VideoModule
  | VideoAndListItemsModule
  | VideoCtaModule
  | null;

export interface ModuleHeader {
  title: string;
  description: string;
}

export interface ModuleOptions {
  color:
    | GeneralModuleColor
    | EmphasizeModuleColor
    | SplitCtaColor
    | CalloutColor;
  showBorderTop: boolean;
  showBorderBottom: boolean;
  callout: Callout;
}

export interface ModuleOptionsGeneralColor
  extends Omit<ModuleOptions, 'color'> {
  color: GeneralModuleColor;
}

export interface RoundedBgLinkCardsModule {
  type: 'roundedBgLinkCardsModule';
  id: string;
  renderTwoCardsInARowForTablet: boolean;
  cards: {
    id: string;
    type: string;
    title: string;
    description: string;
    image: Image;
    backgroundColor: string;
    url: string;
  }[];
}

export interface IDxListItem {
  id: string;
  title: string;
  diagnosisDescription: string;
  commonSymptoms: PortableText;
  image: Image;
  imageBgColor?: string;
  slug?: string;
  symptomCheckerButtonUrl?: string;
}

export interface DxListModule {
  type: 'dxList';
  id: string;
  moduleTitle: string;
  items: IDxListItem[];
}

export interface InstagramFeedModule {
  type: 'instagramFeed';
  id: string;
  title: string;
  buttonLabel: string;
  buttonUrl: string;
}

export interface NavList {
  [slug: string]: NavListItem;
}

export interface NavListItem {
  _id: string;
  children: NavListItemChild[];
  label: string;
  labelFormat?: string;
  order: number;
  parent: string;
  slug: string;
  trendingArticles: TrendingArticle[];
}

export interface NavListItemChild {
  _id: string;
  label: string;
  labelFormat?: string;
  parent: string;
  slug: string;
  trendingArticles: TrendingArticle[];
}

export interface NotificationBarText {
  [index: number]: any;
}

export interface NotificationBar {
  isActive: boolean;
  text: NotificationBarText;
}

export interface NumberedListItem {
  id: string;
  title: string;
  text: PortableText;
  image?: Image;
  statisticsList: {
    highlightedText: string;
    statisticText: PortableText;
    textColor: string;
    source: PortableText;
  }[];
  displayDividerBetweenStatisticsListAndText: boolean;
  desktopImageAlignment: 'left' | 'right' | null;
}

export interface NumberedListWithImageModule {
  type: 'numberedListWithImage';
  id: string;
  moduleOptions: ModuleOptions;
  numberOptions: {
    displayNumber: boolean;
    bgColor: SplitCtaColor;
    color: EmphasizeModuleColor | null;
  };
  moduleTitle: string;
  moduleDescription: PortableText;
  topImage: Image;
  contents: NumberedListItem[];
}

export interface OverviewInfoItem {
  content?: string | Array<[day: string, timeSummary: string]>;
  image: FC;
  isExpandable: boolean;
  status?: string;
  statusOverflowMessage?: JSX.Element;
  title?: string;
  type:
    | 'age'
    | 'averageExperience'
    | 'contact'
    | 'hours'
    | 'insurance'
    | 'languages'
    | 'locationAndDistance'
    | 'locations'
    | 'newPatient'
    | 'pricing';
  url?: string | null;
}

export interface PageContext {
  article?: Article | null;
  blogCategories?: IBlogCategoryPage[] | null;
  blogPost?: BlogPost | null;
  errorPage?: ErrorPage | null;
  drugPage?: DrugPage;
  featureFlags?: FeatureFlags | null;
  frontPage?: GenericPage;
  globalSettings?: GlobalSettingsReducer | null;
  page?: GenericPage;
  quiz?: Quiz | null;
}

export interface PageHero {
  variant: string;
  title: string;
  description: string;
  color: EmphasizeModuleColor;
  desktopImage: Image;
  mobileImage: Image;
  desktopImageWidth: number;
  mobileImageWidth: number;
  modules: PageHeroModule[];
  buttonLink: string;
  buttonLabel: string;
  buttonVariant:
    | 'hero'
    | 'inline-primary-md'
    | 'inline-primary-md-white'
    | 'symptom-checker-blue'
    | 'symptom-checker-white'
    | '';
  gtmTrackerEventName: string;
}

export interface PauseUxCta {
  displayPopUp: boolean;
}

export interface PauseUxCtaSymptomChecker extends PauseUxCta {
  variant: 'symptom-checker';
}

export interface PauseUxCtaBuoyServices extends PauseUxCta {
  variant: 'buoy-services';
  header: string;
  description: string;
  backgroundColor: string;
  image: Image;
  items: Array<{
    id: string;
    type: 'listItem' | 'refillsListItem' | 'otcListItem';
    title: string;
    subtitle: string;
    url: string;
    image: Image;
  }>;
  disclaimer: string;
}

export interface PopUp {
  isActive: boolean;
  text: PortableText;
  continueButtonLabel: string;
  secondaryButtonLabel: string;
  secondaryButtonLink: string;
}

export type PortableText = object[];

export interface PracticeName {
  id: string;
  name: string;
  logo: Image;
  link: {
    label: string;
    url: string;
  };
}

export enum QuizType {
  Sx = 'Sx',
  Dx = 'Dx',
}

export interface QuoteItem {
  type: 'quoteItem';
  id: string;
  quote: string;
  attribution: PortableText;
  description: string;
  button: Button;
}

export interface QuoteModule {
  type: 'quote';
  id: string;
  quote: PortableText[];
  quoteDesktopFontSize: FontSizes.xl | FontSizes.lg;
  attribution: string;
  moduleOptions: ModuleOptions;
}

export interface IFeaturedLinkModule {
  type: 'featuredLink';
  id: string;
  description: PortableText[];
  buttonText: string;
  buttonUrl: string;
  image: Image;
  productName: string;
  productSummary: string;
  moduleHeader: string;
  showTopDivider: boolean;
  editorsChoice: boolean;
}

export interface IQueryModuleQuery {
  question: string;
  answer?: PortableTextBlock;
  typeFormID?: string;
  miniInterview?: MiniInterviewType;
}

export interface IQueryModule {
  type: 'queryModule';
  id: string;
  title: string;
  queries: IQueryModuleQuery[];
  displaySearchBar: boolean;
  sideNav: ContentAnchor;
}

export type SanityDocument = {
  articleType: string | null;
  categories: IBlogCategoryPage[];
  firstName: string | null;
  honorifics: string | null;
  language: string | null;
  lastName: string | null;
  name: string | null;
  slug: string;
  title: string;
  type: string | null;
};

export type SeoSettings = {
  title: string;
  description: string;
  image: Image;
  structuredData: string | null;
  canonicalUrl: string;
};

export interface ShowcasePage {
  id: string;
  type: 'showcasePage';
  title: string;
  slug: string;
  hero: PageHero;
  modules: ShowcasePageModule[];
}

export type ShowcasePageModule =
  | AccordionListModule
  | BannerModule
  | CarouselModule
  | EmphasisLinkCardModule
  | FourColumnVideoModule
  | GeneralInquiryModule
  | ImageCardsModule
  | InstagramFeedModule
  | JobPostingsModule
  | LargeTextModule
  | LinkCardsModule
  | LinkCardWithImageModule
  | LinksListModule
  | NumberedListWithImageModule
  | QuoteModule
  | SingleBlockCtaModule
  | SplitCtaModule
  | TeamListModule
  | TextModule
  | ThreeColumnInfoModule
  | ITypeformModule
  | VideoModule
  | null;

export interface SingleBlockCtaModule {
  type: 'singleBlockCta';
  id: string;
  text: string;
  link: string;
  slug: string;
  variant: 'with-arrow' | 'underlined';
  size: 'medium' | 'large';
  moduleOptions: ModuleOptions;
  gtmTrackerEventName: string;
}

export interface SliderModule {
  type: 'slider';
  id: string;
  title: string;
  titleIcon: Image;
  image: Image;
  backgroundImage: Image;
  slides: {
    id: string;
    text: string;
    label: string;
  }[];
}

export interface SplitCtaModule {
  type: 'splitCta';
  id: string;
  title: string;
  description: string;
  downloadLink: string;
  slug: string;
  moduleOptions: ModuleOptions;
}

export enum Status {
  IDLE = 'IDLE',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  FULFILLED = 'FULFILLED',
}

export interface ICareOption {
  id: string;
  careSubtype: string;
  careOptionContent: PortableText;
}

export interface SurveyChoice {
  id: string;
  text: string;
}

export interface SurveyPage {
  id: string;
  title: string;
  description: string;
  questions: SurveyQuestion[];
}

export interface SurveyQuestion {
  id: string;
  family: string;
  subtype: string;
  context?: {
    // row_id for matrix rating type questions
    id: string;
    text: string;
  };
  heading: string;
  choices: SurveyChoice[];
}

export interface SurveyResponse {
  id: string | null;
  pages: SurveyResponsePagesById;
}

export interface SurveyResponsePagesById {
  [pageId: string]: {
    id: string;
    questions: SurveyResponsePagesQuestionsById;
  };
}

export interface SurveyResponsePagesQuestionsById {
  [questionId: string]: {
    id: string;
    answers: SurveyResponseAnswer[];
  };
}

export interface SurveyResponseRequestBody {
  pages: SurveyResponseRequestBodyPage[];
}

export interface SurveyResponseRequestBodyPage {
  id: string;
  questions: SurveyResponseRequestBodyQuestion[];
}

export interface SurveyResponseRequestBodyQuestion {
  id: string;
  answers: SurveyResponseAnswer[];
}

export interface SurveyResponseAnswer {
  row_id?: string;
  choice_id: string;
}

export interface SymptomChecker {
  buttonLabelMobile: string;
  buttonLabelDesktop: string;
  buttonUrl: string;
  buttonGtmTrackerEventName: string;
}

export interface Tabs extends TabProps {
  label: string;
  'aria-label': string;
  value: string;
  href: string;
  icon: JSX.Element;
  onClick: () => void;
  fontSize?: FontSizes;
}

export interface TeamListModule {
  type: 'teamList';
  id: string;
  moduleHeader: ModuleHeader;
  contents: TeamListModuleContent[];
  moduleOptions: ModuleOptions;
}

export interface TeamListModuleContent extends TeamMember {
  type: string;
}

export interface TeamMember {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  slug: string;
  jobTitle?: string;
  image: Image;
  bio?: PortableText;
}

export interface TeamMemberPage extends TeamMember {
  modules: Module[];
}

export interface TesterRecruitment {
  banner: {
    displayBanner: boolean;
    mainCta: string;
    supportingCta: string;
  };
  popUp: {
    title: string;
    description: PortableText;
    subtitle: string;
    ctaLabel: string;
  };
}

export interface TesterRecruitmentCtaModule {
  type: 'testerRecruitmentCta';
  id: string;
  mainCta: PortableText;
  supportingCta: PortableText;
  moduleOptions: ModuleOptions;
}

export interface TextItem extends CarouselItemOption {
  type: 'textItem';
}

export interface TextModule {
  type: 'textModule';
  id: string;
  addToSeoFaqJsonSchema: boolean;
  anchor: string;
  title: string;
  moduleHeader: ModuleHeader;
  button: {
    label: string;
    url: string;
    variant: 'underline-white' | 'underline-black' | 'primary';
  };
  titleFontSize: FontSizes.lg | FontSizes.xl;
  contents: PortableText;
  contentFont: Fonts;
  contentFontSize: FontSizes.sm | FontSizes.md;
  contentTitleAlignment: 'left' | 'center';
  contentAlignment: 'left' | 'center';
  contentIsTwoColumnOnDesktop: boolean;
  separateTitleAndContentOnDesktop: boolean;
  contentIsFullWidth: boolean;
  moduleOptions: ModuleOptions;
  contentTag: ContentTag;
}

export interface ThreeColumnInfoModule {
  type: 'threeColumnInfo';
  id: string;
  anchor: string;
  moduleHeader: ModuleHeader;
  button: {
    label: string;
    url: string;
    variant: 'underline-white' | 'underline-black' | 'primary';
  };
  numberOptions: {
    displayNumber: boolean;
    bgColor: Color;
    color: Color;
  };
  contentAlign: 'left' | 'center';
  isTwoColumnOnMobile: boolean;
  maxItemsPerPage: number;
  tabs: string[];
  contents: ThreeColumnInfoModuleContents;
  moduleOptions: ModuleOptions;
}

export interface ThumbnailCardsModule {
  type: 'thumbnailCards';
  id: string;
  cards: {
    id: string;
    title: string;
    description: PortableText;
    url: string;
    image: Image;
    backgroundColor: string;
  }[];
}

export type ThunkResult<R> = ThunkAction<R, GlobalState, undefined, Action>;

export type ThreeColumnInfoModuleContents = {
  [tab: string]: ThreeColumnInfoModuleContent[];
};

export type ThreeColumnInfoModuleContent =
  | ThreeColumnModuleManualContent
  | ThreeColumnModuleArticleLink
  | ThreeColumnModuleBlogPostLink
  | null;

export interface ThreeColumnModuleArticleLink {
  type: 'article';
  id: string;
  tabs: string[];
  article: ArticleLink;
}

export interface ThreeColumnModuleBlogPostLink {
  type: 'blogPost';
  id: string;
  tabs: string[];
  blogPost: BlogPostLink;
  description: string;
  image: Image;
  imageBackgroundColor: string;
}

export interface ThreeColumnModuleManualContent {
  type: 'manualContent';
  id: string;
  url: string;
  ariaLabel: string;
  image: Image;
  title: string;
  description: string;
  button: {
    label: string;
    ariaLabel: string;
    url: string;
  };
  tabs: string[];
  content: PortableTextBlock;
}

export interface IBrowsePage {
  articles: Array<ArticleLink | IDrugLink>;
  articleCount?: number;
  documentType: string;
  hero: BrowsePageHero;
  id: string;
  seo: SeoSettings;
  slug: string;
  title: string;
  type: ArticleType;
}

export interface BrowsePageHero {
  title: string;
  description: string;
  color: EmphasizeModuleColor;
  imageBackgroundColor: string;
  image: Image;
}

export interface TranslatedPageReference {
  language: string;
  slug: string;
}

export interface ValueProposition {
  id: string;
  image: Image;
  header: string;
  description: string;
}

export interface VerifiedByExpertsPopUp {
  subheading: string;
  heading: string;
  headingImage: Image;
  headingBackgroundColor: string;
  text: PortableText;
}

export interface Video {
  vimeoId: string;
  title?: string;
  youtubeId: string;
}

export interface VideoAndListItemsModule {
  type: 'videoAndListItemsModule';
  id: string;
  header: string;
  video: Video;
  listItems: ListItemText[];
}

export interface VideoItem extends CarouselItemOption {
  type: 'videoItem';
  video: Video;
}

export interface VideoModule extends Video {
  id: string;
  type: 'videoModule';
}

export type WebToLeadSubmissionMethod = 'salesforce' | 'chili-piper';

export interface IEmailSubscribeForm {
  conditionName?: string | null;
  email: string;
  pathname: string;
}

export interface IBrowsePageLink {
  id: string;
  title: string;
  slug: string;
  url: string;
}

export interface IPredictor {
  name: string;
  category: string;
  predictor_id: number;
  status: string;
  slugs: string[];
}

export interface IInterview {
  _key: string;
  question: string;
  questions?: IInterviewQuestion[];
  interview: MiniInterviewType;
  intro?: string;
  outcomeCopy?: {
    header: string;
    explanation: string;
    outcome: string;
    color?: string;
  }[];
  supplierCtaCardConfigs?: ISupplierCardConfig[];
  treatment?: {
    name: string;
    abbreviation?: string;
  };
}

export interface IEmbeddedInterview {
  id: string;
  type: 'embeddedInterview';
  title?: string;
  description?: string;
  interviews: IInterview[];
  customInterviews?: IInterview[];
}

export interface QuizTextModule extends Omit<TextModule, 'contents'> {
  contents: PortableTextBlock[];
}

export type QuizModule = IEmbeddedInterview | QuizTextModule;

export interface QuizMetaInfo {
  author: Author | null;
  reviewer: Author | null;
  publishedDate: string;
  modifiedDate: string;
}

export interface Quiz {
  id: string;
  title: string;
  conditionName: string;
  slug: string;
  type: 'quiz';
  quizType: 'dxQuiz' | 'sxQuiz';
  modules: QuizModule[];
  seo: ArticleSeoSettings;
  meta: QuizMetaInfo;
}

interface QuizSlugType {
  current: string;
  _type: string;
}

export interface ProsAndConsList {
  id: string;
  type: 'prosAndConsList';
  pros: string[];
  cons: string[];
}

export interface ProductCards {
  id: string;
  type: 'productCards';
  products: Array<{
    header: string;
    linkLabel: string;
    url: string;
    image: Image;
    bulletPoints: Array<{
      icon: TreatmentSupplierIcon;
      text: string;
    }>;
  }>;
}

export interface SupplierCards {
  id: string;
  type: 'supplierCards';
  suppliers: Array<{
    headerIntroText: string;
    header: string;
    bulletPoints: string[];
    buttonText: string;
    buttonUrl: string;
  }>;
}

export type IconConfig = {
  name: string;
  fill: string;
  stroke: string;
};

export type ContentTag = {
  text: string;
  icon: IconConfig;
};

export interface ProvidersRouteParams {
  kind: string | null;
  state: string | null;
  city: string | null;
}

export interface ProvidersSummaryTree {
  label: string;
  children: string[] | ProvidersSummaryTree[];
  footer?: string;
}

export interface ProviderPage {
  type: 'provider';
  summary: ProvidersSummary;
  seo: Omit<SeoSettings, 'image'>;
}

export type GenericProvider = {
  id: string;
  name: string;
  city: string;
  state: string;
  kinds: string[];
  source: string;
  photo: string | null;
  link: string;
  educations: string[];
  networks: string[];
  certifications: string[];
  languages: string[];
  affiliations: string[];
  gender: string;
  summary: string;
  starRating: number | null;
  starCount: number | null;
  phoneNumber: string | null;
  locations: Array<{
    street: string;
    city: string;
    state: string;
    zip: string;
  }>;
  hospitalAffiliations: Array<{
    name: string;
    address: string;
  }>;
  timeZone: string;
  fullAddress: string;
};
