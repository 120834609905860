import ClientCookies from 'js-cookie';

import { Action, GeocodeResponse } from 'types';
import { GEOLOCATION, USER_LOCATION } from 'constants/Geolocation';
import isLocalhost from 'utils/isLocalhost';

export interface GeolocationReducer {
  /** Determined by our geocode service; cannot be altered by the user */
  browserLocation: GeocodeResponse | null;
}

const setBrowserLocationCookie = (loc: GeocodeResponse | null = null) => {
  ClientCookies.set(GEOLOCATION.cookieName, JSON.stringify(loc), {
    domain: isLocalhost() ? 'localhost' : '.buoyhealth.com',
    expires: USER_LOCATION.expires,
    path: '/',
  });
};

function initialState(): GeolocationReducer {
  let initialUserLocation = null;

  const userSharedLocationCookie = ClientCookies.get(USER_LOCATION.cookieName);
  const geolocationCookie = ClientCookies.get(GEOLOCATION.cookieName);

  if (typeof window !== 'undefined') {
    let locationCookie = null;

    if (userSharedLocationCookie && userSharedLocationCookie !== 'undefined') {
      locationCookie = userSharedLocationCookie;
    } else if (geolocationCookie && geolocationCookie !== 'undefined') {
      locationCookie = geolocationCookie;
    }

    initialUserLocation = JSON.parse(locationCookie || 'null') || null;
  }

  return {
    browserLocation:
      geolocationCookie && geolocationCookie !== 'undefined'
        ? JSON.parse(geolocationCookie)
        : null,
  };
}

function geolocation(
  state: GeolocationReducer = initialState(),
  action: Action,
): GeolocationReducer {
  switch (action.type) {
    case 'FETCH_USER_LOCATION_FULFILLED': {
      if (action.payload) {
        setBrowserLocationCookie(action.payload);
      }
      return {
        ...state,
        browserLocation: action.payload || null,
      };
    }
    default:
      return state;
  }
}

export default geolocation;
