import React, { FC } from 'react';
import { Svg } from '../base';

type Props = {
  className?: string;
};

const HealthCareWorkerTalkingToPatient: FC<Props> = ({ className = '' }) => (
  <Svg
    width="100%"
    height="100%"
    viewBox="0 0 400 400"
    fill="transparent"
    className={className}
  >
    <desc>Illustration of a health care worker talking to a patient.</desc>
    <path
      fill="#AB7060"
      d="M107.8,103.6c0.6,3.2,1.3,6.4,3,9.1c1.6,2.8,4.3,5.1,7.5,5.5c4.9,0.7,9.4-3.2,11.2-7.8c1.8-4.6,1.3-13.4,1.1-19.6c0-2.2-0.5,0.5-2.1-1c-1.2-1.1-2.9-1.5-4.6-1.7c-4.4-0.5-12-0.2-15.5,3.1C105.2,94.2,107,99.7,107.8,103.6z"
    />
    <path
      fill="#4C3731"
      d="M266.3,65.4c-2.6,2.1-3.9,6.1-2.3,9c1.6,3,6.4,3.8,8.5,1.1c-1.3,0-2.2,1.5-2,2.8s1.4,2.3,2.5,2.9c4.7,2.8,11.4,1.4,14.8-2.9c1.4,2.6,4,4.4,6.8,4.8c2.9,0.4,5.9-0.6,8-2.6c0.8,2.5,4.5,3,6.5,1.3c2-1.7,2.5-4.6,2.1-7.2c-0.5-3.9-2.6-7.6-5.6-10.1c-4-3.3-9.4-4.3-14.6-4c-4.8,0.3-9.5,2.9-14.2,3C272.8,63.7,269.8,62.6,266.3,65.4z"
    />
    <rect x="39.9" y="199.4" fill="#0641BD" width="320.2" height="119.9" />
    <path
      fill="#F8EDEB"
      d="M255.5,292.1c0,0,13.9,0.3,25.7,0.3c18.1,0,29.7-0.4,29.7-0.4l0,0l-0.1-28.9c0,0-38.2,1.4-41.2,2.5C257.2,270,256.5,286.8,255.5,292.1z"
    />
    <path
      fill="#F8EDEB"
      d="M204.9,288.8c2.2,4.2,13.7,3.5,25.5,3.5l30.7-0.2l-1.5-29.1c0,0-38.2,1.4-41.2,2.5C206,270,202.4,284,204.9,288.8z"
    />
    <path
      fill="#F7DED7"
      d="M242.8,174.6c8.9-22.2,0.7-53.7,10.7-65.7c5-6,8.1-8.3,19.9-9.1c22.1-1.5,42-2,57.5,13.8c14.5,14.8,16.4,37.5,17.4,58.1c0.5,10.5,0.3,22.5-7.7,29.2c-6.5,5.4-15.7,5.2-24.1,4.8c-13-0.7-26.1-1.4-39.1-2C266.7,203.2,232.5,200.2,242.8,174.6z"
    />
    <path
      fill="#F3AB96"
      d="M305.6,57.5v25.8c0,5.5-3.2,10.3-7.8,12.6v4.9c0,5.1-4.1,9.2-9.2,9.2l0,0c-5.1,0-9.2-4.1-9.2-9.2v-4.7c-4.8-2.3-8.1-7.1-8.1-12.8V57.5H305.6z"
    />
    <path
      fill="#F3AB96"
      d="M267,75.9c0.4,2.2,1.8,4.1,3.7,5.2c0.4,0.2,0.9,0.4,1.4,0.2c0.5-0.2,0.7-0.8,0.9-1.3c0.7-2.2,1.2-4.5,1.6-6.9c0.1-1,0.2-2.1-0.3-2.9c-0.6-0.9-1.7-1.3-2.7-1.2C268,69.2,266.4,72.7,267,75.9z"
    />
    <path
      fill="#F3AB96"
      d="M309.4,74.4c-0.4,2.2-1.8,4.1-3.7,5.2c-0.4,0.2-0.9,0.4-1.4,0.2c-0.5-0.2-0.7-0.8-0.9-1.3c-0.7-2.2-1.2-4.5-1.6-6.9c-0.1-1-0.2-2.1,0.3-2.9c0.6-0.9,1.7-1.3,2.7-1.2C308.4,67.7,310,71.2,309.4,74.4z"
    />
    <path
      fill="#F3AB96"
      d="M268.9,81c0.4,14.2,11.3,17.8,18.6,17.8c13.6-0.2,18-7.9,19.1-14.9c0.8-5.1,0.4-10.5-1.3-15.4s-5-9.2-9.5-11.3c-3.9-1.8-8.4-1.8-12.6-0.9c-5.1,1-8.6,4.2-11.5,9.3C268.9,70.5,268.8,75.9,268.9,81z"
    />
    <path
      fill="#8CB5FE"
      d="M223.3,202.5L223.3,202.5l27.3-21.5l81.6-1v16.7v1.1v5.2c0,13.3-9,24.5-21.3,27.8v34.8h-40.6v-33.8h-10.4v33.8h-40.6v-52.2C219.2,209.2,220.8,205.4,223.3,202.5z"
    />
    <path
      fill="#F3AB96"
      d="M229.6,173.2c5.2-7,9.6-12.1,11.6-17.1c1.2-3,0.2-25.2,4.6-38.5c1.1-3.4,7.8-8.5,18.1-8.4c0,0,1.9,17,1.7,21.4c-0.8,17.7-2.4,34.8-7.2,41.9c-5.9,8.8-21,17.3-26.4,26.6c-2.9,5.1,1-9.4-5.9-8.5c-5,0.7-9.7,2.7-8.5-0.3C220.1,184.1,225.1,179.2,229.6,173.2z"
    />
    <path
      fill="#F7DED7"
      d="M255.4,105.4c-2.7,2-7,5.4-10.1,11.2c-6.3,12.2-9.5,28.2-10.5,37c-0.4,3.9,1.6,7.7,5.1,9.5c5.9,3,15.9,3.2,25.3,3c4.2-0.1,5.9-9,5.8-13.3c-0.4-16.5-1.2-36.1-1.7-52.5C269.4,100.4,262.2,100.4,255.4,105.4z"
    />
    <path
      fill="#F3AB96"
      d="M294.6,182.3c-3.7,4.2-8.6,9.8-11.2,16.6c-2.3,5.9,7.5,10.5,9.2,8.2c5.7-8,10.7-10.3,19.7-14.2c9.3-4,20.1-9,22.2-11.3c6.7-6.8,4.1-16.9,3.9-26.5c0-1-12.9,0.5-14.2,1c-3,1.1-3.9,3.9-6.1,6.1C310.8,169.6,301.4,174.6,294.6,182.3z"
    />
    <path
      fill="#F7DED7"
      d="M322.3,111c2.9,1.8,5,4.4,6.9,7.2c8.1,11.4,12.8,18.2,16.4,31.7c0.8,2.9,1.2,6.5,0.6,9.5c-0.8,3.6,0.9,15-2.3,16.8c-3.2,1.8-33.1-3.4-37.9-10.6c-2.3-3.5,5.1-5.1,5.2-9.3c0.4-16.5,0.9-32.9,1.3-49.4C312.7,101,319,108.9,322.3,111z"
    />
    <path
      fill="#F3AB96"
      d="M236.7,204.6c-0.3-3.8-0.3-8.3-0.6-12.1c-0.1-1.6-0.5-2.6-0.8-3.2c0.7-1.7,1.1-3.2,1.1-4.1c0-2.7-1.1-0.9-3.2-2.7c-1.9-1.6-4.6-7.8-5.7-6.4c-8.6,10.7-14,13.3-14.2,20.7c0,1.4,0,2.9,0.2,4.3c-0.1,2.2,0.2,4.5,0.7,6.6c0.1,0.6,0.3,1.2,0.7,1.7c0.4,0.5,1,0.8,1.7,0.7c0.2,1.2,0.5,2.5,1,3.6c0.4,0.9,0.9,1.9,1.8,2.4c0.8,0.6,2.1,0.6,2.8-0.1c0.4,1.4,0.9,2.6,2.3,3c1.3,0.4,2.7-0.3,2.5-2.2c1.2,2.1,3.5,2,4.1,0.7c1.4-3.1-1.2-5.6-0.8-12.8c0-0.3,0-0.7,0-1c0.1-1.4,0.3-2.7,0.6-3.7c0.1-0.2,0.2-0.6,0.3-0.9c0.5,2.1,1.2,4.5,2.1,5.6C235.1,207.3,236.8,207.1,236.7,204.6z"
    />
    <path
      fill="#F3AB96"
      d="M281.6,214.2c0,3-0.7,9.3,4.1,9.7c0.1,1.3,0.3,2.8,3.2,3.1c1.4,0.1,3.5-1,3.3-2.2c1.6,1.2,3.8-0.2,4.7-1.9c0.9-1.7,0.9-2.5,0.6-4.4c0.9,0.6,2.2,0.1,2.7-0.8s0.3-3.5,0.7-7.4c1.1-9.5-1-15.3,1.3-17c1.5-1.2,8.5-6.4,7-7.7c-2.2-2.1-6-1.2-8.9,0.1c-5.4,2.5-11.6,6.3-15.4,10.7C281.2,200.7,281.5,208.8,281.6,214.2z"
    />
    <path
      fill="#F7DED7"
      d="M66.8,357.9c0,0,75.6-0.2,94.2-0.2c12.2,0,26.1-2.8,26.1-15.1c0-12.7-27-14.2-37.3-14.5c-15.3-0.5-75.3,0.7-82.9,3.8C66.9,331.9,66.6,349.4,66.8,357.9z"
    />
    <path
      fill="#AB7060"
      d="M66.4,234.9l0.1,105c0,0,8.9-0.1,16.5-0.1c13.1-0.1,23-0.5,23-0.5l2.8-65.1L66.4,234.9z"
    />
    <path
      fill="#F7DED7"
      d="M53.4,141c-7.9,11.7-12.6,25.9-9.6,39.1c4,17.8,23.1,31.1,42.7,29.8c19.6-1.4,37.5-17.2,40-35.5c1.5-10.5-1.6-20.9-5.7-30.6c-3.4-8.2-7.5-16.1-13.6-22.7c-6.7-7.2-10.9-17.9-22.1-12.2C66.8,118.3,60.5,130.5,53.4,141z"
    />
    <polygon
      fill="#AB7060"
      points="117.1,233.8 116.1,338.7 156.1,338.9 156.7,270.7"
    />
    <path
      fill="#F7DED7"
      d="M62.7,193c0.9-8.4-10.9-0.8-10.4-9.3c0.9-16.5,4.5-34,7.6-43.5c3.1-9.6,11-22.3,18.9-28.2c4.2-3.1,12.3-12.8,43.7-11.3c16,0.8,17.6,5.3,25.1,11.9c18.4,16.4,19,84.5,20.8,102.6c1.6,16.2,3.4,91.2,3.4,91.2c-7.4,2.1-94.2,5.3-118.3,1C53.5,307.4,60.7,210.9,62.7,193z"
    />
    <path
      fill="#AB7060"
      d="M228.2,112.1c-1-0.3-2.1-0.1-2.9,0.4c-0.8,0.5-1.5,1.3-1.8,2.3l1.1-4.2c0.5-2-0.7-4.1-2.7-4.7c-2-0.5-4.1,0.7-4.7,2.7l1-3.8c0.5-2-0.7-4.1-2.7-4.7c-2-0.5-4.1,0.7-4.7,2.7l-1,3.8c0.5-2-0.7-4.1-2.7-4.7l0,0c-2-0.5-4.1,0.7-4.7,2.7l-4.9,19l-1.9-5.5c-0.7-2-2.9-3.1-4.9-2.4c-2,0.7-3.1,2.9-2.6,5.8c0.7,4.2,1.2,7.1,3.8,15.8c2.6,8.8,8,9.8,15.4,11.7c5.5,1.4,12.7,0.4,16-7.4c0.8-1.9,2-5.9,2.6-7.9c1.2-3.6,4.2-14.8,4.2-14.8C231.3,115,230.2,112.7,228.2,112.1z"
    />
    <path
      fill="#F7DED7"
      d="M136.4,166.8c4.7,1.8,21.8,26.2,50.2,20.1c14.5-3.1,27.4-23.6,30.9-32.9c2.9-7.7,2.4-16.6-5.7-21.2c-4.2-2.4-13.8-5.2-19.3,0.8c-2.8,3-7.8,11.5-19.9,4c-15.6-9.8-17.6-27.4-37.5-34.7C118.5,96.8,127.9,163.6,136.4,166.8z"
    />
    <path
      fill="#F8EDEB"
      d="M274,77c0-1.9,1.9-3.6,4.3-3.7c0.2,0,0.3,0,0.5,0c2.6,0,4.8,1.7,4.8,3.8c0,2.1-2.1,3.8-4.8,3.8c-0.2,0-0.3,0-0.5,0C275.9,80.5,274,78.9,274,77z"
    />
    <path
      fill="#4C3731"
      d="M274,77c0-1.9,1.9-3.6,4.3-3.7c0.9,1,1.4,2.3,1.4,3.7c0,1.5-0.5,2.8-1.4,3.7C275.9,80.5,274,78.9,274,77z"
    />
    <path
      fill="#F8EDEB"
      d="M293,77c0-1.9,1.9-3.6,4.3-3.7c0.2,0,0.3,0,0.5,0c2.6,0,4.8,1.7,4.8,3.8c0,2.1-2.1,3.8-4.8,3.8c-0.2,0-0.3,0-0.5,0C294.8,80.5,293,78.9,293,77z"
    />
    <path
      fill="#4C3731"
      d="M293,77c0-1.9,1.9-3.6,4.3-3.7c0.9,1,1.4,2.3,1.4,3.7c0,1.5-0.5,2.8-1.4,3.7C294.8,80.5,293,78.9,293,77z"
    />
    <path
      fill="#AB7060"
      d="M135.3,94.4c2.5-2.6,3-4.2,3.5-7.8c0.4-2.8-0.2-6.2,0.1-7.6c0.3-1.2,3.1-1.4,2.5-3.7c-0.4-1.4-3.8-2-3.8-6.6c0-7.7-3.3-14.9-5.7-9c-1.6,3.9-7.8-3.2-11.8-2.1c-7.4,2-13.2,8.7-14.4,16.2c-1.1,7.2,2.8,17,8.8,21.5C119.9,99,130.7,99.2,135.3,94.4z"
    />
    <path
      fill="#4C3731"
      d="M79.3,74.4c-1.8,7.5-3.1,15.3-6.7,22.1c-1.8,3.5-4.2,6.7-5.7,10.3c-1.5,3.7-1.8,8.1,0.3,11.3c2.3,3.4,6.6,4.7,10.7,5.5c13,2.6,30.4-0.4,39.6-7.7c15.4-12.1,8.7-30.1,7.8-34.2c-4.6-21.1,10.2-15.8,12-23.2c2.3-9.5-6.6-14-17.2-16C99.1,38.8,83.8,56.1,79.3,74.4z"
    />
    <path
      fill="#F8EDEB"
      d="M136.2,73.4c0-1.9-1.9-3.6-4.3-3.7c-0.2,0-0.3,0-0.5,0c-2.6,0-4.8,1.7-4.8,3.8s2.1,3.8,4.8,3.8c0.2,0,0.3,0,0.5,0C134.3,77,136.2,75.4,136.2,73.4z"
    />
    <path
      fill="#4C3731"
      d="M136.2,73.4c0-1.9-1.9-3.6-4.3-3.7c-0.9,1-1.4,2.3-1.4,3.7s0.5,2.8,1.4,3.7C134.3,77,136.2,75.4,136.2,73.4z "
    />
    <path
      fill="#8CB5FE"
      d="M259.1,68.3c6.3,2.8,16,3.9,29.3,4c11.4,0,24.2-1.6,29.5-4.4l-8.9-6.6c-1.4-5.8,0.2-13.5-5.5-16.7c-5.7-3.2-28-2.3-32,0.2c-4.2,2.7-4,9.4-4.2,16.7L259.1,68.3z"
    />
  </Svg>
);

export default HealthCareWorkerTalkingToPatient;
