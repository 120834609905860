import { applyMiddleware, createStore, combineReducers } from 'redux';
import { createWrapper, HYDRATE, MakeStore } from 'next-redux-wrapper';
import { composeWithDevTools } from 'redux-devtools-extension';

import promise from 'redux-promise-middleware';
import thunk, { ThunkMiddleware } from 'redux-thunk';

import { REDUX_LOGS_ENABLED } from 'constants/EnvVars';
import reducers from 'state/reducers';

import { Action, Dispatch, GlobalState } from 'types';

const middleware = [thunk as ThunkMiddleware<GlobalState, Action>, promise];

const allReducers = combineReducers(reducers);

export const rootReducer = (state: GlobalState | undefined, action: Action) => {
  // See: https://github.com/kirill-konshin/next-redux-wrapper/tree/6.x#usage
  if (action.type === HYDRATE) {
    if (!state) {
      return;
    }

    const clientState = state;
    const serverState = action.payload;

    return {
      ...clientState,
      ...serverState,

      // Feature flag client-side state can be set by the edge middleware and server-side state
      // could be created during static build. Thus, client-side state is more accurate for the
      // user in this context.
      featureFlags: {
        ...serverState.featureFlags,
        ...clientState.featureFlags,
      },
      i18n: {
        ...serverState.i18n,
        ...clientState.i18n,
      },
      // Prevent clearing out user location when route changes.
      ...(clientState.geolocation.browserLocation
        ? {
            geolocation: clientState.geolocation,
          }
        : {}),
    };
  }

  return allReducers(state, action);
};

const makeStore: MakeStore<GlobalState> = () => {
  const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware<Dispatch, GlobalState>(...middleware)),
  );

  return store;
};

export const reduxWrapper = createWrapper<GlobalState>(makeStore, {
  debug: REDUX_LOGS_ENABLED,
});
