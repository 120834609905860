import { ActivityTrackingConfiguration } from '@snowplow/browser-tracker';

// Page Anchors
export const PageMainContentId = 'main-content';
export const UserStories = 'user-stories';
export const DrugPageFdaOfficialData = 'fda-official-data';
export const DrugPageSummary = 'summary';
export const DrugPageWhatIs = 'what-it-is';
export const DrugPageAlternateDrugs = 'alternate-drugs';
export const DrugPageSideEffects = 'side-effects';
export const DrugPageSafetyFacts = 'safety-facts';
export const DrugPageDrugInteractions = 'drug-interactions';
export const DrugPageComparePrices = 'compare-rx-prices';
export const DrugPageReviews = 'reviews';
export const DrugPageReviewForm = 'review';
export const DrugPageReferences = 'references';

export const GOOGLE_TAG_MANAGER_ID = 'GTM-KJQWJS';

export const SnowplowActivityTrackingConfig: ActivityTrackingConfiguration = {
  minimumVisitLength: 15,
  heartbeatDelay: 15,
};

export const FacebookPixelId = '1462331520793021';

export const CacheControlHeaders = {
  MAX_AGE_1_MONTH: 'max-age=2592000',
  MAX_AGE_1_DAY: 'max-age=86400',
  /*
   * Clients can cache a resource but must revalidate each time before using it.
   * HTTP request occurs each time, but it can skip downloading the HTTP body if it can
   * verify via ETag or Last-Modified headers that it already has the recent version cached.
   *
   * Equivalent to "max-age=0, must-revalidate"
   */
  NO_CACHE: 'no-cache',
};
